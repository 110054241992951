<script>
export default {
  name: "BtnLink",
  props: {
    href: {
      type: String,
      required: false,
      default: "",
    },
    white: {
      type: Boolean,
      required: false,
      defualt: false,
    },
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<template>
  <a class="link" :class="{ white: white, big: big }" :href="href">
    <slot></slot>
  </a>
</template>

<style scoped lang="scss">
.link.big:deep(span) {
  background: linear-gradient(
    90deg,
    #1d0b05 23.75%,
    #5f5f5f 41.57%,
    #100505 65.45%,
    #35261d 90.96%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 12px 5px;
  width: 100%;
  font-family: Circe, serif;
  border-radius: 100px;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  background: linear-gradient(180deg, #ffc46b 0%, #a1722b 100%);
  filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.15));
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: linear-gradient(0deg, #ffc46b 0%, #a1722b 79.63%);
  }

  &.white {
    background: linear-gradient(180deg, #fcfcfc 0%, #787878 100%);
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.15));

    &:hover {
      background: linear-gradient(0deg, #f3f3f3 0%, #787878 81.48%);
    }
  }

  &.big {
    font-family: Playfair Display, serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;

    @include tablets {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>
