<script>
import GradientText from "@/components/ui/GradientText.vue";
import BtnLink from "@/components/ui/BtnLink.vue";
import { reactive, ref } from "vue";

export default {
  name: "InfoSection",
  components: { BtnLink, GradientText },
  setup() {
    const cerfList = reactive([
      {
        title: "сертификат на 10000 рублей",
        img: "cerf-10000",
        price: 10000,
        id: 1,
      },
      {
        title: "сертификат на 30000 рублей",
        img: "cerf-30000",
        price: 30000,
        id: 2,
      },
      {
        title: "сертификат на 50000 рублей",
        img: "cerf-50000",
        price: 50000,
        id: 3,
      },
      {
        title: "сертификат на 100000 рублей",
        img: "cerf-100000",
        price: 100000,
        id: 4,
      },
      {
        title: "сертификат на 200000 рублей",
        img: "cerf-200000",
        price: 200000,
        id: 5,
      },
    ]);
    const currentCerf = ref(10000);

    return {
      cerfList,
      currentCerf,
    };
  },
};
</script>

<template>
  <section class="info">
    <img
      src="@/assets/uploads/img/info/info-bg-left.png"
      alt="золотая фольга"
      loading="lazy"
      class="info__left-bg"
    />
    <img
      src="@/assets/uploads/img/info/info-bg-right.png"
      alt="золотая фольга"
      loading="lazy"
      class="info__right-bg"
    />
    <div class="container">
      <div class="info__wrapper">
        <h2 data-aos="zoom-in" class="special__title">
          <GradientText>Лучший подарок</GradientText>
          - это здоровье и красота!
        </h2>

        <div class="info__list-wrapper">
          <ul class="info__list">
            <li class="info__item">
              <img src="@/assets/uploads/img/star.png" alt="звездочка" />
              <p>Можно ли подарить здоровье, красоту, молодость?</p>
            </li>
            <li class="info__item">
              <img src="@/assets/uploads/img/star.png" alt="звездочка" />
              <p>
                Подарочные сертификаты на любые услуги во всех клиниках К+31 –
                это возможность проявить внимание и заботу без слов о любви и
                обнять близкого человека даже на расстоянии. Приобрести
                подарочные карты Вы можете на ресепшне во всех клиниках К+31 или
                онлайн.
              </p>
            </li>
          </ul>
        </div>

        <h3 class="info__subtitle info__subtitle--big">
          <GradientText
            >Выбирайте один из форматов сертификатов – дарите подарки и
            наслаждение лучшими услугами для всей семьи:</GradientText
          >
        </h3>
      </div>

      <div class="info__certificates">
        <div class="info__prices">
          <button
            @click="currentCerf = cefr.price"
            v-for="cefr in cerfList"
            :key="cefr.id"
            type="button"
            class="info__price"
            :class="{ active: currentCerf === cefr.price }"
          >
            {{ cefr.price }}
          </button>
        </div>
        <div class="info__certificates-list">
          <div
            v-for="cefr in cerfList"
            :key="cefr.id"
            class="info__certificate-wrap"
            :class="{ active: cefr.price === currentCerf }"
          >
            <div
              v-show="cefr.price === currentCerf"
              class="info__certificate__link"
            >
              <a
                href="https://www.k31.ru/patient/promo/cert21/"
                target="_blank"
                class="info__certificate"
              >
                <img
                  src="@/assets/uploads/img/info/info-foil.png"
                  alt="золотая фольга"
                  class="info__foil"
                />
                <img
                  :src="require(`@/assets/uploads/img/info/${cefr.img}.png`)"
                  :alt="cefr.title"
                  class="info__picture-cerf"
                />
              </a>
              <BtnLink
                href="https://www.k31.ru/patient/promo/cert21/"
                class="info__btn"
                target="_blank"
              >
                <span>Купить</span>
              </BtnLink>
            </div>
          </div>
        </div>
      </div>

      <div class="info__bottom">
        <h2 data-aos="zoom-in" class="special__title">
          <GradientText>Условия и сроки действия акции</GradientText>
        </h2>
        <p class="info__text">
          Рекламная кампания “Чудесный Новый год” в К+31 и акции в её поддержку
          действуют с 1.12.2024г. по 31.01.2025 г. Условия акций в рамках
          рекламной кампании “Чудесный Новый Год”: скидки предоставляются
          первичным и повторным пациентам клиник К+31, скидки не суммируются с
          другими видами акций и специальных предложение, не суммируются с
          баллами по программе лояльности, действуют только для физических лиц.
          Более подробную информацию об акциях в рамках действия рекламной
          кампании“Чудесный Новый год” Вы можете узнать по телефону колл-центра
          <a href="tel:+74951045105">+7 (495) 104-51-05</a> или обратиться к
          администраторам в клиниках К+31.
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.info {
  padding-top: 80px;
  position: relative;
  background: linear-gradient(135deg, #0a0603 7.4%, #3a170c 47.22%);

  @include tablets {
    padding-top: 50px;
  }
}

.info__left-bg {
  position: absolute;
  left: 0;
  bottom: 80px;
  z-index: 1;
}

.info__right-bg {
  position: absolute;
  right: 0;
  bottom: 80px;
  z-index: 1;
}

.container {
}

.info__wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.special__title {
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 0.48px;

  @include tablets {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 30px;
  }

  @include phone {
    max-width: 288px;
    margin: 0 auto 16px auto;
  }
}

.info__list-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.info__list {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.44px;

  @include tablets {
    font-size: 16px;
    line-height: 20px;
  }

  & img {
    @include tablets {
      max-width: 50px;
    }
  }
}

.info__item {
  max-width: 820px;
  display: flex;
  align-items: center;
  gap: 6px;
  @include tablets {
    align-items: center;
  }

  &:not(:last-child) {
    margin-bottom: 26px;

    @include tablets {
      margin-bottom: 22px;
    }
  }

  &:last-child {
    margin-bottom: 35px;

    @include tablets {
      margin-bottom: 12px;
    }
  }
}

.info__subtitle {
  margin-bottom: 40px;
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 112.5% */
  letter-spacing: 0.32px;

  @include tablets {
    font-size: 20px;
    line-height: 24px;
  }
  @include phone {
    margin: 0 auto;
    max-width: 330px;
  }

  &--big {
    margin-bottom: 50px;
  }
}

.info__certificates {
  margin-bottom: 80px;
}

.info__prices {
  display: none;
  margin-bottom: 5px;
  position: relative;
  z-index: 5;

  @include tablets {
    display: flex;
    justify-content: space-around;
    gap: 5px;
  }

  @include phone {
    justify-content: space-between;
  }
}

.info__price {
  margin-bottom: 0;
  position: relative;
  font-family: Circe;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-bottom: 5px;
  border: unset;
  transition: all 0.3s ease-in-out;
  color: rgba(255, 255, 255, 0.7);

  @include tablets {
    font-size: 16px;
  }

  &.active {
    font-weight: 700;

    color: #fff;
  }

  &.active:before {
    width: 100%;
  }

  &:before {
    position: absolute;
    display: block;
    content: "";
    height: 3px;
    width: 0;
    bottom: 0;
    left: 50%;
    transition: all 0.3s ease-in-out;
    transform: translateX(-50%);
    background: linear-gradient(90deg, #ffb97a 7.65%, #a26c23 71.64%);
  }
}

.info__certificates-list {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
  @include tablets {
    gap: 0;
  }
}
.info__certificate-wrap {
  max-width: 0;
  width: 100%;
  max-height: 330px;
  height: 100%;
  @media (min-width: 993px) {
    max-width: 370px;
  }
}
.info__certificate-wrap.active {
  max-width: 370px;
}

.info__certificate__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 993px) {
    display: flex !important;
  }
}
.info__certificate {
  cursor: pointer;
  max-width: 370px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover .info__foil {
    transform: translateY(-10px);
  }
}

.info__foil {
  top: -15%;
  left: -10%;
  position: absolute;
  max-width: 270px;
  z-index: 1;
  transition: transform 0.2s ease-in-out;

  @include phone {
    left: 0%;
    top: -5%;
    max-width: 247px;
    max-height: 220px;
  }
}

.info__picture-cerf {
  position: relative;
  z-index: 2;
  max-width: 400px;
  max-height: 275px;
  height: 100%;
  width: 100%;

  @include phone {
    max-height: 270px;
  }
}

.info__btn {
  max-width: 200px;
}

.info__bottom {
  transform: translateY(50px);
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 55px 105px;
  border-radius: 36px;
  border: 4px solid #58311a;
  background: linear-gradient(133deg, #1a0b07 36.27%, #3b180d 82.31%);

  @include tablets {
    padding: 30px 35px;
  }
}

.info__text {
  & a {
    text-decoration: underline;
  }
}
</style>
