<template>
  <header class="header">
    <div class="container header__container">
      <div class="header__wrap">
        <div class="header__logo">
          <a href="/" class="logo">
            <div class="logo__picture">
              <img alt="logo" src="@/assets/uploads/logo.svg" />
            </div>
            <div class="logo__desc">Все плюсы <br />сильной медицины</div>
          </a>
        </div>
        <div class="header__right">
          <div class="header__right-top">
            <div class="header__contacts header-contacts">
              <div class="header-contacts__list">
                <div class="header-contacts__item header-contacts__item--phone">
                  <a href="tel:+74951045117" class="header-contacts__item-link">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.7164 15.5653C20.6797 15.5348 16.5286 12.5773 15.4078 12.7621C14.8671 12.8577 14.5583 13.2267 13.9387 13.9647C13.839 14.0838 13.5988 14.3683 13.4132 14.5711C13.0216 14.4436 12.6396 14.2881 12.2702 14.1059C10.3633 13.1775 8.82254 11.6368 7.89416 9.72983C7.71199 9.36043 7.55651 8.97845 7.42893 8.58683C7.63247 8.4006 7.9177 8.16037 8.03954 8.05791C8.77408 7.44175 9.14239 7.13229 9.23793 6.59091C9.43385 5.47006 6.46523 1.32037 6.43477 1.28299C6.29958 1.09125 6.12351 0.931933 5.91926 0.81652C5.71501 0.701107 5.48769 0.632483 5.2537 0.615601C4.05047 0.615601 0.615234 5.07129 0.615234 5.82245C0.615234 5.86606 0.678234 10.2996 6.14539 15.8609C11.7005 21.3218 16.134 21.3848 16.1776 21.3848C16.9281 21.3848 21.3845 17.9496 21.3845 16.7464C21.3674 16.5123 21.2986 16.285 21.1831 16.0808C21.0676 15.8765 20.9082 15.7005 20.7164 15.5653ZM16.1008 19.9961C15.4999 19.9448 11.7752 19.454 7.12293 14.8841C2.53085 10.2089 2.05247 6.47806 2.0047 5.89999C2.91216 4.47566 4.00809 3.18062 5.2627 2.05006C5.29039 2.07775 5.32708 2.11929 5.37416 2.17329C6.33634 3.48676 7.16521 4.89286 7.84847 6.37075C7.62628 6.59428 7.39143 6.80486 7.14508 7.00145C6.76306 7.29253 6.41226 7.62245 6.09831 7.98591C6.04519 8.06044 6.00737 8.14477 5.98705 8.23402C5.96673 8.32326 5.96431 8.41565 5.97993 8.50583C6.12644 9.14049 6.35083 9.75461 6.648 10.3342C7.7127 12.5205 9.47938 14.287 11.6658 15.3514C12.2453 15.649 12.8595 15.8736 13.4942 16.0201C13.5844 16.0358 13.6768 16.0333 13.7661 16.013C13.8553 15.9927 13.9396 15.9549 14.0142 15.9018C14.3789 15.5865 14.71 15.2343 15.0021 14.8508C15.2195 14.5919 15.5095 14.2464 15.6196 14.1488C17.1013 14.8314 18.5105 15.6613 19.8261 16.6259C19.8835 16.6744 19.9244 16.7118 19.9514 16.736C18.8208 17.991 17.5255 19.0872 16.1008 19.9947V19.9961Z"
                      />
                      <path
                        d="M11.6923 6.15402C12.7936 6.15512 13.8495 6.59311 14.6283 7.37187C15.4071 8.15063 15.8451 9.20654 15.8462 10.3079C15.8462 10.4915 15.9191 10.6676 16.0489 10.7974C16.1788 10.9272 16.3549 11.0002 16.5385 11.0002C16.7221 11.0002 16.8982 10.9272 17.028 10.7974C17.1578 10.6676 17.2308 10.4915 17.2308 10.3079C17.2291 8.83949 16.6451 7.43171 15.6068 6.39341C14.5685 5.3551 13.1607 4.77106 11.6923 4.76941C11.5087 4.76941 11.3326 4.84235 11.2028 4.97218C11.0729 5.10201 11 5.27811 11 5.46172C11 5.64533 11.0729 5.82142 11.2028 5.95125C11.3326 6.08109 11.5087 6.15402 11.6923 6.15402Z"
                      />
                      <path
                        d="M11.6923 2.69211C13.7113 2.69449 15.6469 3.49759 17.0746 4.92524C18.5022 6.35288 19.3053 8.2885 19.3077 10.3075C19.3077 10.4911 19.3806 10.6672 19.5105 10.797C19.6403 10.9269 19.8164 10.9998 20 10.9998C20.1836 10.9998 20.3597 10.9269 20.4895 10.797C20.6194 10.6672 20.6923 10.4911 20.6923 10.3075C20.6896 7.92139 19.7405 5.6338 18.0532 3.94657C16.366 2.25934 14.0784 1.31024 11.6923 1.3075C11.5087 1.3075 11.3326 1.38043 11.2028 1.51027C11.0729 1.6401 11 1.81619 11 1.9998C11 2.18341 11.0729 2.35951 11.2028 2.48934C11.3326 2.61917 11.5087 2.69211 11.6923 2.69211Z"
                      />
                    </svg>
                    +7 (495) 104-51-17
                  </a>
                </div>

                <div
                  class="header-contacts__item header-contacts__item--social"
                >
                  <a
                    href="https://api.whatsapp.com/send?phone=79162591131&amp;text=&amp;source=&amp;data=&amp;app_absent="
                    target="_blank"
                    class="indicator__button indicator__button--whatsapp"
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.63678 21.5026L2.10627 16.2834C1.1613 14.6781 0.663769 12.8515 0.663769 10.9752C0.663769 5.19774 5.36405 0.497467 11.1415 0.497467C16.9189 0.497467 21.6191 5.19774 21.6191 10.9752C21.6191 16.7526 16.9189 21.4529 11.1415 21.4529C9.34126 21.4529 7.57851 20.9921 6.0191 20.1171L0.63678 21.5026ZM6.29427 18.2109L6.61498 18.4067C7.9746 19.2367 9.53987 19.6755 11.1415 19.6755C15.9388 19.6755 19.8417 15.7725 19.8417 10.9752C19.8417 6.17779 15.9388 2.27483 11.1415 2.27483C6.3441 2.27483 2.44113 6.17779 2.44113 10.9752C2.44113 12.6467 2.91622 14.27 3.81491 15.6694L4.03089 16.0058L3.18467 19.0113L6.29427 18.2109Z"
                        fill="white"
                      />
                      <path
                        d="M8.19814 6.09853L7.51827 6.06147C7.30472 6.04982 7.09525 6.12118 6.93384 6.26139C6.60424 6.54762 6.07722 7.10097 5.91535 7.82205C5.67396 8.89723 6.04701 10.2138 7.01251 11.5304C7.97801 12.8469 9.77729 14.9534 12.959 15.8531C13.9842 16.143 14.7908 15.9476 15.413 15.5495C15.9059 15.2343 16.2456 14.7282 16.3681 14.1562L16.4766 13.6492C16.5111 13.488 16.4293 13.3245 16.2796 13.2555L13.9819 12.1964C13.8327 12.1277 13.6558 12.1711 13.5555 12.3011L12.6535 13.4705C12.5853 13.5589 12.4687 13.5939 12.3634 13.5569C11.7456 13.3398 9.67638 12.4732 8.54097 10.2861C8.49173 10.1912 8.50398 10.0761 8.57382 9.99518L9.43591 8.99788C9.52399 8.89604 9.54623 8.75286 9.49331 8.62911L8.50286 6.31188C8.45013 6.18852 8.33197 6.10584 8.19814 6.09853Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>

                <div
                  class="header-contacts__item header-contacts__item--callback"
                >
                  <a href="https://www.k31.ru/schedule/" target="_blank"
                    >Записаться</a
                  >
                </div>
                <div
                  class="header-contacts__item header-contacts__item--payment"
                >
                  <a
                    class="header-contacts__item-link"
                    href="https://www.k31.ru/patient/pay/element/"
                    target="_blank"
                    >Онлайн-оплата</a
                  >
                </div>
              </div>
              <div class="header__cabinet">
                <a href="https://lk.k31.ru" target="_blank">
                  <div class="header__cabinet-link">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 14.875C12.9617 14.875 11.9466 14.5671 11.0833 13.9902C10.2199 13.4133 9.54699 12.5934 9.14963 11.6341C8.75227 10.6748 8.64831 9.61918 8.85088 8.60078C9.05345 7.58238 9.55347 6.64692 10.2877 5.91269C11.0219 5.17847 11.9574 4.67845 12.9758 4.47588C13.9942 4.27331 15.0498 4.37727 16.0091 4.77463C16.9684 5.17199 17.7883 5.8449 18.3652 6.70826C18.9421 7.57161 19.25 8.58665 19.25 9.625C19.25 11.0174 18.6969 12.3527 17.7123 13.3373C16.7277 14.3219 15.3924 14.875 14 14.875ZM14 6.125C13.3078 6.125 12.6311 6.33027 12.0555 6.71486C11.4799 7.09944 11.0313 7.64607 10.7664 8.28561C10.5015 8.92515 10.4322 9.62888 10.5673 10.3078C10.7023 10.9867 11.0356 11.6104 11.5251 12.0999C12.0146 12.5894 12.6383 12.9227 13.3172 13.0577C13.9961 13.1928 14.6999 13.1235 15.3394 12.8586C15.9789 12.5937 16.5256 12.1451 16.9101 11.5695C17.2947 10.9939 17.5 10.3172 17.5 9.625C17.5 8.69674 17.1313 7.8065 16.4749 7.15013C15.8185 6.49375 14.9283 6.125 14 6.125Z"
                      />
                      <path
                        d="M13.9996 27.125C12.0592 27.1237 10.1433 26.6922 8.38969 25.8615C6.6361 25.0309 5.08851 23.8218 3.85832 22.3212L3.40332 21.7612L3.85832 21.21C5.08955 19.7114 6.6376 18.5043 8.39112 17.6755C10.1446 16.8467 12.0601 16.4169 13.9996 16.4169C15.9391 16.4169 17.8545 16.8467 19.608 17.6755C21.3615 18.5043 22.9096 19.7114 24.1408 21.21L24.5958 21.7612L24.1408 22.3212C22.9106 23.8218 21.363 25.0309 19.6095 25.8615C17.8559 26.6922 15.9399 27.1237 13.9996 27.125ZM5.69582 21.77C6.76003 22.9092 8.04706 23.8176 9.47702 24.4386C10.907 25.0596 12.4493 25.3801 14.0083 25.3801C15.5673 25.3801 17.1097 25.0596 18.5396 24.4386C19.9696 23.8176 21.2566 22.9092 22.3208 21.77C21.2566 20.6307 19.9696 19.7224 18.5396 19.1013C17.1097 18.4803 15.5673 18.1598 14.0083 18.1598C12.4493 18.1598 10.907 18.4803 9.47702 19.1013C8.04706 19.7224 6.76003 20.6307 5.69582 21.77Z"
                      />
                      <path
                        d="M14 27.1249C11.0186 27.1269 8.12543 26.1138 5.79658 24.2524C3.46772 22.391 1.84198 19.7921 1.18694 16.8836C0.531899 13.9751 0.886587 10.9303 2.19263 8.25022C3.49867 5.57016 5.67823 3.41461 8.37259 2.13833C11.067 0.862055 14.1155 0.541115 17.0166 1.22833C19.9177 1.91555 22.4983 3.56997 24.3338 5.9193C26.1694 8.26863 27.1503 11.1729 27.1153 14.154C27.0803 17.1352 26.0314 20.0156 24.1412 22.3211C22.911 23.8217 21.3634 25.0308 19.6099 25.8615C17.8563 26.6921 15.9403 27.1236 14 27.1249ZM14 2.62489C11.7502 2.62489 9.55097 3.29202 7.68036 4.54192C5.80975 5.79182 4.35179 7.56835 3.49084 9.64686C2.6299 11.7254 2.40463 14.0125 2.84354 16.219C3.28245 18.4256 4.36581 20.4524 5.95663 22.0432C7.54746 23.6341 9.57429 24.7174 11.7808 25.1563C13.9874 25.5952 16.2745 25.37 18.353 24.509C20.4315 23.6481 22.208 22.1901 23.4579 20.3195C24.7078 18.4489 25.375 16.2497 25.375 13.9999C25.375 10.9831 24.1765 8.08978 22.0433 5.95655C19.9101 3.82332 17.0168 2.62489 14 2.62489Z"
                      />
                      <path
                        d="M4.53223 21.7698C4.53223 21.7698 13.3435 31.6136 22.3122 22.7498L23.4672 21.7698C23.4672 21.7698 15.9772 13.9998 8.37348 18.6636L4.53223 21.7698Z"
                      />
                      <path
                        d="M14 14C16.4162 14 18.375 12.0412 18.375 9.625C18.375 7.20875 16.4162 5.25 14 5.25C11.5838 5.25 9.625 7.20875 9.625 9.625C9.625 12.0412 11.5838 14 14 14Z"
                      />
                    </svg>
                    <span>Личный кабинет</span>
                  </div>
                </a>
              </div>
            </div>

            <div class="header__indicator">
              <a
                href="https://www.k31.ru/search/"
                target="_blank"
                class="indicator__button"
              >
                <svg
                  width="28"
                  height="27"
                  viewBox="0 0 28 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.0719 18.1832C19.6655 17.8092 19.0329 17.8355 18.6589 18.2419C18.2849 18.6483 18.3112 19.2809 18.7175 19.6549L20.0719 18.1832ZM25.5844 25.9745C25.9908 26.3485 26.6234 26.3223 26.9974 25.9159C27.3714 25.5095 27.3451 24.8769 26.9388 24.5029L25.5844 25.9745ZM18.4482 18.4708C14.983 22.2361 9.1214 22.4794 5.35607 19.0141L4.00171 20.4857C8.5798 24.699 15.7066 24.4032 19.9199 19.8251L18.4482 18.4708ZM5.35607 19.0141C1.59074 15.5488 1.34749 9.68723 4.81277 5.9219L3.34113 4.56754C-0.872137 9.14563 -0.576386 16.2724 4.00171 20.4857L5.35607 19.0141ZM4.81277 5.9219C8.27804 2.15657 14.1396 1.91332 17.9049 5.3786L19.2593 3.90696C14.6812 -0.306307 7.5544 -0.0105558 3.34113 4.56754L4.81277 5.9219ZM17.9049 5.3786C21.6703 8.84387 21.9135 14.7054 18.4482 18.4708L19.9199 19.8251C24.1331 15.247 23.8374 8.12023 19.2593 3.90696L17.9049 5.3786ZM18.7175 19.6549L25.5844 25.9745L26.9388 24.5029L20.0719 18.1832L18.7175 19.6549Z"
                  />
                </svg>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=79162591131&amp;text=&amp;source=&amp;data=&amp;app_absent="
                target="_blank"
                class="indicator__button"
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="#43D854" />
                  <path
                    d="M13.0017 6H12.9983C9.13863 6 6 9.1395 6 13C6 14.5312 6.4935 15.9505 7.33263 17.1029L6.46025 19.7034L9.15088 18.8433C10.2578 19.5765 11.5781 20 13.0017 20C16.8614 20 20 16.8596 20 13C20 9.14038 16.8614 6 13.0017 6Z"
                    stroke="white"
                  />
                  <path
                    d="M17.0794 15.8854C16.9105 16.3623 16.2403 16.7578 15.7056 16.8733C15.3399 16.9511 14.8621 17.0133 13.2539 16.3465C11.1968 15.4943 9.872 13.4039 9.76875 13.2683C9.66988 13.1326 8.9375 12.1614 8.9375 11.1569C8.9375 10.1524 9.44763 9.66327 9.65325 9.45327C9.82213 9.2809 10.1013 9.20215 10.369 9.20215C10.4556 9.20215 10.5335 9.20652 10.6035 9.21002C10.8091 9.21877 10.9124 9.23102 11.048 9.55565C11.2169 9.96252 11.6281 10.967 11.6771 11.0703C11.727 11.1735 11.7769 11.3135 11.7069 11.4491C11.6413 11.5891 11.5835 11.6513 11.4803 11.7703C11.377 11.8893 11.279 11.9803 11.1758 12.108C11.0813 12.2191 10.9745 12.3381 11.0935 12.5438C11.2125 12.745 11.6238 13.4161 12.2293 13.9551C13.0106 14.6508 13.6441 14.873 13.8708 14.9675C14.0396 15.0375 14.2409 15.0209 14.3643 14.8896C14.5209 14.7208 14.7143 14.4408 14.9111 14.1651C15.0511 13.9674 15.2279 13.9429 15.4134 14.0129C15.6024 14.0785 16.6025 14.5729 16.8081 14.6753C17.0138 14.7785 17.1494 14.8275 17.1993 14.9141C17.2483 15.0008 17.2483 15.4076 17.0794 15.8854Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div class="header__right-bottom">
            <div class="header__nav">
              <nav class="header-nav">
                <a href="https://www.k31.ru/info/about/" target="_blank"
                  >О К+31</a
                >
                <a href="https://www.k31.ru/specialisty/" target="_blank"
                  >Врачи</a
                >
                <a href="https://www.k31.ru/service/" target="_blank"
                  >Отделения</a
                >
                <a href="https://www.k31.ru/program/" target="_blank"
                  >Программы</a
                >
                <a href="https://www.k31.ru/info/promo/" target="_blank"
                  >Акции</a
                >
                <a href="https://www.k31.ru/price/" target="_blank">Цены</a>
                <a href="https://www.k31.ru/patient/" target="_blank"
                  >Пациентам</a
                >
                <a href="https://www.k31.ru/contacts/" target="_blank"
                  >Контакты</a
                >
              </nav>
            </div>
          </div>
        </div>
        <div class="header__hamburger">
          <div class="hamburger js-showmenu" @click="showMenu">
            <div class="hamburger__inner"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <AddMenu :menuIsOpen="menuIsOpen" @close="closeMenu"></AddMenu>
</template>

<script>
import { ref } from "vue";
import AddMenu from "@/components/layout/AddMenu.vue";
export default {
  name: "TheHeader",
  components: { AddMenu },
  setup() {
    const menuIsOpen = ref(false);
    const showMenu = () => {
      menuIsOpen.value = true;
    };
    const closeMenu = () => {
      menuIsOpen.value = false;
    };
    return {
      showMenu,
      menuIsOpen,
      closeMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
.header__container {
  max-width: 1350px;
}

.header__hamburger {
  display: none;
}

.header-contacts__item--social {
  display: none;
}

.header {
  position: absolute;
  top: 9px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  font-family: "Circe";

  svg {
    fill: #fff;
  }
}

.header__indicator {
  display: flex;
  align-items: center;
  margin-left: auto;
  @media only screen and (max-width: 599px) {
    display: none;
  }
}

.indicator__button {
  width: 26px;
  height: 26px;
  margin: 0 0 0 32px;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__right {
  max-width: 800px;
}

.header__right-top {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  margin-bottom: 24px;
}

.header__contacts {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 0.03em;
}

.header__right-top {
  display: flex;
  align-items: center;
}

.header-contacts__list {
  display: flex;
  align-items: center;
  column-gap: 28px;
  margin-right: 28px;
}

.header-contacts__item-link {
  color: inherit;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  letter-spacing: 0.18px;
}

.header-contacts__item--phone a svg {
  display: none;
  font-size: 21px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.header-contacts__item--phone {
  @media only screen and (max-width: 599px) {
    a {
      display: none;
    }
  }
}

.header-contacts__item {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.18px;
  text-decoration: none;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.header-contacts__item--callback a {
  color: #080704;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 100px;
  background: #ffd18d;
  padding: 12px 24px;
  text-decoration: none;
  display: block;
}

.logo__desc {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.6px;
  margin-left: 15px;
  display: inline-block;
  vertical-align: middle;
  @media (max-width: 1130px) {
    display: none;
  }
}

.header-nav {
  display: flex;
  justify-content: space-between;

  a {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.54px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.header__cabinet {
  a {
    text-decoration: none;
  }
}

.header__cabinet-link {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.18px;
  }
}

.hamburger {
  width: 38px;
  height: 38px;
  position: relative;
}

.hamburger__inner {
  background: #fff;
  width: 100%;
  height: 3px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:before {
    top: -14px;
    background: #fff;
    content: "";
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    width: 100%;
  }

  &:after {
    background: #fff;
    bottom: -14px;
    content: "";
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .header__hamburger {
    display: block;
  }
  .header-contacts__item--payment {
    display: none;
  }
  .header__nav {
    display: none;
  }
  .header__cabinet {
    display: none;
  }
  .header__right-top {
    margin-bottom: 0;
  }
  .header-contacts__list {
    flex-direction: row-reverse;
  }
}
</style>
