<template>
  <div class="location-main" id="address-map">
    <div class="location-main__container">
      <h2 data-aos="zoom-in" class="location-main__title">
        <GradientText>Наши клиники</GradientText>
      </h2>
      <div class="location-main__wrap">
        <yandex-map
          class="yandex-map"
          :settings="{
            location: {
              center: center,
              zoom: 10,
            },
          }"
          width="100%"
          height="500px"
        >
          <yandex-map-default-scheme-layer />
          <yandex-map-default-features-layer />
          <yandex-map-marker
            v-for="cord in coordinatesClinic"
            :key="cord.id"
            :settings="{ coordinates: cord.cord }"
          >
            <div
              @click="activeClinic = cord.id"
              class="yandex-map__marker"
              :class="{ active: cord.id === activeClinic }"
            >
              <div class="yandex-map__marker-wrap">
                <img
                  class="yandex-map__img"
                  alt="метка клиники на карте"
                  src="@/assets/uploads/icon/clinic-map.svg"
                />
              </div>
            </div>
          </yandex-map-marker>
        </yandex-map>
        <div class="location-main__addresses">
          <div class="location-main__slider fader">
            <div
              :class="{ active: activeClinic === 1 }"
              class="location-main__slide number-slide1"
            >
              <div class="location-main__addresses-item">
                <div class="location-main__addresses-title">
                  К+31 Лобачевского
                </div>
                <div
                  class="location-main__addresses-text location-main__addresses-desc"
                >
                  <p>119415, г. Москва, ул. Лобачевского, 42с4</p>
                  <p><a href="tel:+74951564120">+7 (495) 156-41-20</a></p>
                </div>
              </div>
              <div
                class="location-main__addresses-item location-main__addresses-popup"
              >
                <div
                  @click="accordion1 = !accordion1"
                  class="location-main__top"
                >
                  <div class="location-main__addresses-subtitle">На метро</div>
                  <img
                    :class="{ open: accordion1 }"
                    src="@/assets/uploads/icon/accordion-arrow.svg"
                    alt="кнопка открытия"
                    class="location-main__arrow-acord"
                  />
                </div>
                <div class="location-main__accordion-wrapper">
                  <div
                    class="location-main__accordion"
                    :class="{ open: accordion1 }"
                  >
                    <div
                      class="location-main__time-list location-main__addresses-inner"
                    >
                      <div class="location-main__time-item">
                        <div class="location-main__time-left">
                          <div class="location-main__time-nums">
                            <div class="location-main__time-num">1</div>
                            <div class="location-main__time-num">11</div>
                          </div>
                        </div>
                        <div class="location-main__time-desc">
                          Станция «Проспект Вернадского»
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="location-main__addresses-item location-main__addresses-popup"
              >
                <div
                  @click="accordion2 = !accordion2"
                  class="location-main__top"
                >
                  <div class="location-main__addresses-subtitle">
                    На личном автомобиле
                  </div>
                  <img
                    :class="{ open: accordion2 }"
                    src="@/assets/uploads/icon/accordion-arrow.svg"
                    alt="кнопка открытия"
                    class="location-main__arrow-acord"
                  />
                </div>
                <div class="location-main__accordion-wrapper">
                  <div
                    class="location-main__accordion"
                    :class="{ open: accordion2 }"
                  >
                    <div
                      class="location-main__addresses-text location-main__addresses-inner"
                    >
                      Двигаясь по ул. Лобачевского, проезжаем первый шлагбаум
                      (пост охраны ГКБ № 31), поворачиваем направо у второго
                      шлагбаума (пост охраны К+31)
                    </div>
                  </div>
                </div>
              </div>
              <div class="location-main__addresses-item">
                <div class="location-main__addresses-subtitle">Часы работы</div>
                <div
                  class="location-main__addresses-text location-main__addresses-inner"
                >
                  Будни: 08:00 – 21:00 <br />
                  Суббота: 09:00 – 19:00 <br />
                  Воскресенье: 09:00 – 18:00 <br />
                </div>
              </div>
            </div>
            <div
              :class="{ active: activeClinic === 2 }"
              class="location-main__slide number-slide2"
            >
              <div class="location-main__addresses-item">
                <div class="location-main__addresses-title">К+31 Запад</div>
                <div
                  class="location-main__addresses-text location-main__addresses-desc"
                >
                  <p>Оршанская, 16с2; Ак. Павлова, 22</p>
                  <p><a href="tel:+74951564120">+7 (495) 156-41-20</a></p>
                </div>
              </div>
              <div
                class="location-main__addresses-item location-main__addresses-popup"
              >
                <div
                  @click="accordion1 = !accordion1"
                  class="location-main__top"
                >
                  <div class="location-main__addresses-subtitle">На метро</div>
                  <img
                    :class="{ open: accordion1 }"
                    src="@/assets/uploads/icon/accordion-arrow.svg"
                    alt="кнопка открытия"
                    class="location-main__arrow-acord"
                  />
                </div>
                <div class="location-main__accordion-wrapper">
                  <div
                    class="location-main__accordion"
                    :class="{ open: accordion1 }"
                  >
                    <div
                      class="location-main__time-list location-main__addresses-inner"
                    >
                      <div class="location-main__time-item">
                        <div class="location-main__time-left">
                          <div class="location-main__time-nums">
                            <div class="location-main__time-num">3</div>
                          </div>
                        </div>
                        <div class="location-main__time-desc">Молодежная</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="location-main__addresses-item location-main__addresses-popup"
              >
                <div
                  @click="accordion2 = !accordion2"
                  class="location-main__top"
                >
                  <div class="location-main__addresses-subtitle">
                    На личном автомобиле
                  </div>
                  <img
                    :class="{ open: accordion2 }"
                    src="@/assets/uploads/icon/accordion-arrow.svg"
                    alt="кнопка открытия"
                    class="location-main__arrow-acord"
                  />
                </div>
                <div class="location-main__accordion-wrapper">
                  <div
                    class="location-main__accordion"
                    :class="{ open: accordion2 }"
                  >
                    <div
                      class="location-main__addresses-text location-main__addresses-inner"
                    >
                      Двигаясь по улице Оршанская, поворачиваем к шлагбауму с
                      постом охраны К+31.
                    </div>
                  </div>
                </div>
              </div>
              <div class="location-main__addresses-item">
                <div class="location-main__addresses-subtitle">Часы работы</div>
                <div
                  class="location-main__addresses-text location-main__addresses-inner"
                >
                  Будни: 08:00 – 21:00 <br />
                  Сб-Вс: 09:00 – 18:00
                </div>
              </div>
            </div>
            <div
              :class="{ active: activeClinic === 3 }"
              class="location-main__slide number-slide3"
            >
              <div class="location-main__addresses-item">
                <div class="location-main__addresses-title">
                  К+31 Петровски Ворота
                </div>
                <div
                  class="location-main__addresses-text location-main__addresses-desc"
                >
                  <p>1-й Колобовский пер., 4</p>
                  <p><a href="tel:+74951564120">+7 (495) 156-41-20</a></p>
                </div>
              </div>
              <div
                class="location-main__addresses-item location-main__addresses-popup"
              >
                <div
                  @click="accordion1 = !accordion1"
                  class="location-main__top"
                >
                  <div class="location-main__addresses-subtitle">На метро</div>
                  <img
                    :class="{ open: accordion1 }"
                    src="@/assets/uploads/icon/accordion-arrow.svg"
                    alt="кнопка открытия"
                    class="location-main__arrow-acord"
                  />
                </div>
                <div class="location-main__accordion-wrapper">
                  <div
                    class="location-main__accordion"
                    :class="{ open: accordion1 }"
                  >
                    <div
                      class="location-main__time-list location-main__addresses-inner"
                    >
                      <div class="location-main__time-item">
                        <div class="location-main__time-left">
                          <div class="location-main__time-nums">
                            <div class="location-main__time-num">9</div>
                          </div>
                        </div>
                        <div class="location-main__time-desc">
                          Цветной Бульвар
                        </div>
                      </div>
                      <div class="location-main__time-item">
                        <div class="location-main__time-left">
                          <div class="location-main__time-nums">
                            <div class="location-main__time-num">10</div>
                          </div>
                        </div>
                        <div class="location-main__time-desc">Трубная</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="location-main__addresses-item location-main__addresses-popup"
              >
                <div
                  @click="accordion2 = !accordion2"
                  class="location-main__top"
                >
                  <div class="location-main__addresses-subtitle">
                    На личном автомобиле
                  </div>
                  <img
                    :class="{ open: accordion2 }"
                    src="@/assets/uploads/icon/accordion-arrow.svg"
                    alt="кнопка открытия"
                    class="location-main__arrow-acord"
                  />
                </div>
                <div class="location-main__accordion-wrapper">
                  <div
                    class="location-main__accordion"
                    :class="{ open: accordion2 }"
                  >
                    <div
                      class="location-main__addresses-text location-main__addresses-inner"
                    >
                      Двигаясь по Петровскому бульвару, поворачиваем на ул.
                      Петровка, сразу после – на 1-й Колобовский пер. Парковка
                      муниципальная
                    </div>
                  </div>
                </div>
              </div>
              <div class="location-main__addresses-item">
                <div class="location-main__addresses-subtitle">Часы работы</div>
                <div
                  class="location-main__addresses-text location-main__addresses-inner"
                >
                  Будни: 08:00 – 21:00<br />
                  Сб-Вс: 09:00 – 19:00
                </div>
              </div>
            </div>
          </div>
          <div class="location-main__addresses-nav">
            <button
              @click="prevClinic"
              type="button"
              class="location-main__nav-arrow location-main__nav-arrow--prev"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="55"
                height="55"
                viewBox="0 0 55 55"
                fill="none"
              >
                <rect
                  x="0.5"
                  y="0.500244"
                  width="54"
                  height="54"
                  rx="27"
                  stroke="#211207"
                />
                <path
                  d="M35 26.5002H18M18 26.5002L26.0526 18.0002M18 26.5002L26.0526 35.0002"
                  stroke="#111111"
                />
              </svg>
            </button>
            <button
              @click="nextClinic"
              type="button"
              class="location-main__nav-arrow location-main__nav-arrow--next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="55"
                height="55"
                viewBox="0 0 55 55"
                fill="none"
              >
                <rect
                  x="0.5"
                  y="0.500244"
                  width="54"
                  height="54"
                  rx="27"
                  stroke="#211207"
                />
                <path
                  d="M18 26.5002H35M35 26.5002L26.9474 18.0002M35 26.5002L26.9474 35.0002"
                  stroke="#111111"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  YandexMap,
  YandexMapDefaultSchemeLayer,
  YandexMapMarker,
  YandexMapDefaultFeaturesLayer,
} from "vue-yandex-maps";
import { onMounted, ref, shallowRef, watch } from "vue";
import GradientText from "@/components/ui/GradientText.vue";

export default {
  name: "MapSection",
  components: {
    GradientText,
    YandexMap,
    YandexMapDefaultSchemeLayer,
    YandexMapMarker,
    YandexMapDefaultFeaturesLayer,
  },
  setup() {
    const center = ref([37.8, 55.75]);
    const accordion1 = ref(false);
    const accordion2 = ref(false);
    const accordion3 = ref(false);
    const activeClinic = ref(1);
    const opacities = ref([]);
    const current = ref(1);
    const coordinatesClinic = shallowRef([
      {
        cord: [37.501824, 55.670067],
        id: 1,
      },
      {
        cord: [37.404555, 55.743811],
        id: 2,
      },
      {
        cord: [37.614159, 55.768876],
        id: 3,
      },
    ]);

    watch(activeClinic, () => {
      accordion1.value = false;
      accordion2.value = false;
      accordion3.value = false;
    });

    const prevClinic = () => {
      if (activeClinic.value === 1) {
        activeClinic.value = 3;
        return;
      }
      activeClinic.value--;
    };
    const nextClinic = () => {
      if (activeClinic.value === 3) {
        activeClinic.value = 1;
        return;
      }
      activeClinic.value++;
    };

    onMounted(() => {
      const width = window.screen.width;
      if (width > 900) {
        center.value = [37.8, 55.75];
        return;
      }

      center.value = [37.55, 55.75];
    });
    return {
      current,
      opacities,
      coordinatesClinic,
      activeClinic,
      prevClinic,
      nextClinic,
      accordion1,
      accordion2,
      accordion3,
      center,
    };
  },
};
</script>

<style lang="scss" scoped>
.location-main {
  padding-top: 120px;
  padding-bottom: 80px;
  background-image: url("~@/assets/uploads/img/map-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @include tablets {
    padding-bottom: 0;
  }
}
.location-main__container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;

  @include tablets {
    padding: 0;
  }
}
.location-main__title {
  display: block;
  margin-bottom: 70px;
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 0.48px;

  @include tablets {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 30px;
  }

  @include phone {
    max-width: 288px;
    margin: 0 auto 16px auto;
  }
}

.location-main__wrap {
  height: 650px;
  display: flex;
  position: relative;

  @include tablets {
    height: 100%;
    flex-direction: column;
  }
}

.location-main__addresses-inner {
  font-size: 16px;
}

.location-main__map {
  height: 100%;
  width: 85%;
}

.location-main__time-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.location-main__addresses-title {
  margin-bottom: 8px;
  color: #211207;
  font-family: Playfair Display, serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.24px;
  margin-bottom: 11px;
}

.location-main__addresses-subtitle {
  //color: #1f1107;
  //font-size: 18px;
  //font-weight: 400;
  //line-height: 24px;
  margin-bottom: 8px;
  color: #211207;
  font-family: Circe;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.location-main__addresses {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  max-height: 610px;
  border-radius: 36px;
  border: 4px solid #58311a;
  background: linear-gradient(139deg, #f6bc65 42.9%, #b7853b 76%);
  color: #fff;
  max-width: 440px;
  width: 100%;
  padding: 40px 0;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -40px;

  @include tablets {
    flex-direction: row;
    position: relative;
    margin-top: 0;
    border: unset;
    border-radius: 0;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr max-content;
  }

  @include phone {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &.location-main__addresses--3 {
    background: #004f61 !important;
  }

  &.location-main__addresses--4 {
    background: #54678d !important;
  }

  .slick-slide.slick-active {
    .location-main__addressess-popover {
      pointer-events: visible;
    }
  }
}

.location-main__addresses-item {
  margin-bottom: 16px;

  @include tablets {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(2) {
    @include tablets {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  &:nth-child(3) {
    @include tablets {
      grid-row-start: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}

.location-main__addresses-name {
  text-transform: uppercase;
}

.location-main__addressess-popover {
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
  color: #ffffff;
  background: none;
  pointer-events: none;

  &:hover {
    color: #ffffff;
  }
}

.location-main__addresses-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.56;
  color: #111;

  a {
    display: block;
    color: #111;
    font-weight: 400;
    margin-top: 16px;

    @include tablets {
      margin-top: 0;
    }
  }

  span {
    font-weight: 400;
    font-size: 16px;
    color: #111;
  }
}

.location-main__addresses-desc {
  @include tablets {
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr;
  }

  @include phone {
    grid-template-columns: 1fr;
  }
}

.__ymap {
  border-radius: 36px;
  border: 4px solid #59321b;
  overflow: hidden;

  @include tablets {
    flex: 1 0 auto;
    border: unset;
    border-radius: 0;
  }
}

.location-main__time-num {
  height: 20px;
  font-weight: 400;
  font-size: 10px;
  color: #eff4fd;
  background: rgba(#111, 0.25);
  border-radius: 10px;
  padding: 5px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.location-main__time-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: #000;
  margin-left: 15px;
}

.location-main__time-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.location-main__addresses.location-main__addresses--3 {
  .location-main__addresses-text {
    color: rgba(255, 255, 255, 0.5);

    a {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.location-main__addresses.location-main__addresses--4 {
  .location-main__addresses-text {
    color: rgba(255, 255, 255, 0.5);

    a {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.location-main__time-nums {
  display: flex;
  align-items: center;
}

.location-main__time-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location-main__addresses-nav {
  position: relative;
  z-index: 5;
  padding: 0 40px;
  gap: 20px;
  display: flex;
  align-items: center;

  @include tablets {
    align-self: end;
    grid-column-start: 2;
    grid-row-start: 1;
  }

  @include phone {
    align-self: center;
  }
}

.location-main__slider {
  position: relative;
  width: 100%;
  height: 100%;

  @include tablets {
    height: 400px;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @include phone {
    height: 100%;
  }
}

.location-main__slide {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding: 0 40px 10px 40px;
  grid-template-rows: repeat(3, max-content);

  @include tablets {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include phone {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.location-main__slide.active {
  z-index: 3;
  opacity: 1;

  @include phone {
    position: relative;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.location-main__nav-arrow {
  width: 55px;
  padding: 0;
  border: unset;
  border-radius: 50%;
  transition: background 0.3s ease-in-out;

  &:hover {
    //background: #ffb978;
    //border: 1px solid #ffb978;
    background: rgb(0, 0, 0, 0.1);
  }
}
.location-main__top {
  position: relative;
  z-index: 25;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include phone {
    border-bottom: 1px solid rgba(30, 23, 20, 0.5);
  }
}
.location-main__arrow-acord {
  display: none;

  &.open {
    transform: rotate(180deg);
  }
  @include phone {
    display: block;
  }
}

.location-main__accordion-wrapper {
  @include phone {
    overflow: hidden;
    position: relative;
  }
}

.location-main__accordion {
  @include phone {
    opacity: 0;
    position: absolute;
    transform: translateY(-120%);
    padding-top: 16px;
    transition: all 0.7s ease-in-out;

    &.open {
      opacity: 1;
      position: relative;
      transform: translateY(0);
    }
  }
}

.yandex-map {
  @include phone {
    height: 295px !important;
  }
}
.yandex-map__marker {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  box-sizing: border-box;
  transform: translate(-50%, calc(-50% - 24px));
  min-width: 50px;
  height: 50px;

  &.active {
    background: rgb(233, 197, 169, 0.9);
    animation: zoom 0.3s ease-in-out forwards;
  }

  &.active .yandex-map__marker-wrap {
    background: white;
  }
}
@keyframes zoom {
  0% {
    transform: translate(-50%, calc(-50% - 24px)) scale(80%);
  }
  100% {
    transform: translate(-50%, calc(-50% - 24px)) scale(100%);
  }
}
.yandex-map__marker-wrap {
  position: absolute;
  min-width: 35px;
  height: 35px;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.yandex-map__img {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<style>
[class*="--main-engine-container"] {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>
