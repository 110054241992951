<script>
import BtnLink from "@/components/ui/BtnLink.vue";
import { computed, ref, toRefs } from "vue";

export default {
  name: "FormSendTo",
  components: { BtnLink },
  emits: ["closeForm"],
  props: {
    promoInfo: {
      type: [Object, null],
      required: true,
      default: null,
    },
  },
  setup(props) {
    const { promoInfo } = toRefs(props);
    const isCopied = ref(false);
    const copyUrl = computed(() => {
      return `https://newyear.k31.ru/?promo_id=${promoInfo.value?.promo_id}`;
    });
    const copyLink = async () => {
      try {
        await navigator.clipboard.writeText(copyUrl.value);
        isCopied.value = true;
      } catch (err) {
        throw new Error(err);
      }
    };

    const replaseStrike = (message) => {
      if (!message) return "";
      return message.replace(/(<([^>]+)>)/gi, "");
    };

    const title = computed(() => {
      if (promoInfo.value?.title) return replaseStrike(promoInfo.value?.title);
      return "";
    });

    const preview = computed(() => {
      if (promoInfo.value?.preview)
        return replaseStrike(promoInfo.value?.preview);
      return "";
    });

    const whatsappLink = computed(() => {
      return `https://api.whatsapp.com/send?text=${title.value}%0D%0A
          ${copyUrl.value}%0D%0A
          ${preview.value}
          `;
    });

    const telegramLink = computed(() => {
      return `https://telegram.me/share/url?url=${copyUrl.value}&text=${title.value}`;
    });

    const viberLink = computed(() => {
      return `viber://forward?text=${title.value}%0D%0A${copyUrl.value}%0D%0A${preview.value}`;
    });

    return {
      copyLink,
      isCopied,
      copyUrl,
      whatsappLink,
      telegramLink,
      viberLink,
    };
  },
};
</script>

<template>
  <div class="send-to">
    <div class="send-to__wrapper">
      <button @click="$emit('closeForm')" type="button" class="send-to__close">
        <img src="@/assets/uploads/icon/close.svg" alt="крестик закрытия" />
      </button>
      <h4 class="send-to__title">
        Пожалуйста, скопируйте ссылку на этот чудесный подарок
      </h4>
      <p class="send-to__text">
        И отправьте ее с помощью мессенджера Вашим близким
      </p>

      <div class="send-to__group">
        <div class="send-to__link">
          {{ copyUrl }}
        </div>
      </div>
      <BtnLink @click.prevent="copyLink" white class="send-to__submit">
        <span v-if="!isCopied">Копировать ссылку</span>
        <span v-else>Скопировано!</span>
      </BtnLink>
      <div class="send-to__shares">
        <a class="send-to__share" :href="telegramLink" target="_blank">
          <img src="@/assets/uploads/img/tg.jpg" alt="telegram" />
        </a>
        <a class="send-to__share" :href="whatsappLink" target="_blank">
          <img src="@/assets/uploads/img/whats.jpg" alt="whatsapp" />
        </a>
        <a class="send-to__share" :href="viberLink" target="_blank">
          <img src="@/assets/uploads/img/viber.jpg" alt="viber" />
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.send-to {
  display: flex;
  justify-content: center;
}

.send-to__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 620px;
  padding: 50px 70px;
  background: linear-gradient(139deg, #f6bc65 42.9%, #b7853b 76%);
  border-radius: 24px;
  border: 3px solid #58311a;
  stroke-width: 4px;
  stroke: #58311a;

  @include phone {
    padding: 40px 10px;
  }
}

.send-to__close {
  position: absolute;
  right: 0;
  top: -35px;
  border: unset;
  padding: 0;
  transition: opacity 0.3s ease-in-out;

  & img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 0.7;
  }
}

.send-to__title {
  color: #150a08;
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.28px;

  @include phone {
    font-size: 20px;
    line-height: 24px;
  }
}

.send-to__text {
  color: #150a08;
  text-align: center;
  font-size: 18px;
  line-height: 22px;

  & span {
    text-transform: uppercase;
  }

  @include phone {
    font-size: 14px;
    line-height: 18px;
  }
}
.send-to__group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.send-to__link {
  border-radius: 5px;
  border: 1px solid var(--Blue-1, #bac7de);
  padding: 16px 24px;
  background-color: white;
  color: #111;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.send-to__shares {
  display: flex;
  justify-content: center;
  gap: 24px;
}
.send-to__share {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  & img {
    border-radius: 10px;
  }

  &:hover img {
    transform: scale(105%);
  }
}
</style>
