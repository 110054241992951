<template>
  <TheHeader />
  <main>
    <HeroSection />
    <SpecialSection :userId="userId" :promoId="promoId" />
    <InfoSection />
    <MapSection />
    <CallSection />
  </main>
  <TheFooter />
</template>

<script>
import HeroSection from "@/components/layout/HeroSection.vue";
import TheHeader from "@/components/layout/TheHeader.vue";
import SpecialSection from "@/components/layout/SpecialSection.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import CallSection from "@/components/layout/CallSection.vue";
import MapSection from "@/components/layout/MapSection.vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import InfoSection from "@/components/layout/InfoSection.vue";
export default {
  name: "App",
  components: {
    InfoSection,
    SpecialSection,
    HeroSection,
    TheHeader,
    TheFooter,
    CallSection,
    MapSection,
  },

  setup() {
    const userId = ref(null);
    const modalIsOpen = ref(false);
    const promoId = ref(null);
    const getUserId = async () => {
      try {
        const response = await axios("/v1/user/uid/");
        if (response.data.status === "ok") {
          return response.data.data.uid;
        }
        return null;
      } catch (err) {
        return null;
      }
    };
    onMounted(async () => {
      userId.value = await getUserId();
      const urlParams = new URLSearchParams(window.location.search);
      const promoIdUrl = urlParams.get("promo_id");
      if (promoIdUrl) {
        promoId.value = promoIdUrl;
      }
    });

    return {
      userId,
      modalIsOpen,
      promoId,
    };
  },
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
@import "@/assets/scss/main.scss";
</style>

<style lang="scss">
div,
span,
p {
  caret-color: transparent;
}

input {
  caret-color: auto;
}
.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}
body {
  color: #fff;
  font-family: Circe, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  @include tablets {
    font-size: 18px;
  }

  @include phone {
    font-size: 14px;
  }
}

.error {
  color: #ff1f00;
}
</style>
