<script>
export default {
  name: "GradientText",
};
</script>

<template>
  <span class="gradient-text">
    <slot></slot>
  </span>
</template>

<style scoped lang="scss">
.gradient-text {
  display: inline-block;
  background: linear-gradient(91deg, #ffb978 4.68%, #ab7a32 81.45%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
