<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SurpriseBoxAnime",
  props: {
    startAnimation: {
      type: Boolean,
      defualt: false,
      required: true,
    },
  },

  setup() {
    const animationActive = ref(null);
    const animationActiveCustom = ref(null);

    return {
      animationActive,
      animationActiveCustom,
    };
  },
});
</script>

<template>
  <div class="surprise-box">
    <div
      class="surprise-box__wrapper skw"
      :class="{
        animate: animationActive,
        'animate-custom': startAnimation,
      }"
    >
      <div class="surprise-box__top">
        <img src="@/assets/uploads/box/box-top.png" alt="крышка" />
      </div>
      <div class="surprise-box__body">
        <img src="@/assets/uploads/box/box-body.png" alt="коробка" />
      </div>
      <div class="surprise-box__ball">
        <img src="@/assets/uploads/box/surprise-ball.png" alt="шар" />
      </div>
      <div class="surprise-box__dust">
        <img src="@/assets/uploads/box/golden-dust.png" alt="золотая пыль" />
      </div>
      <div class="surprise-box__foil">
        <img src="@/assets/uploads/box/golden-foil.png" alt="золотая фольга" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.surprise-box {
  position: relative;
  max-width: 350px;
  max-height: 350px;
  width: 100%;
  height: 100%;

  @include tablets {
    max-width: 230px;
    max-height: 230px;
  }
}

.surprise-box__wrapper {
  position: relative;
  z-index: 1;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -15%);
}

.surprise-box__wrapper.animate {
  & .surprise-box__dust {
    animation: dust 1s forwards;
  }

  & .surprise-box__foil {
    animation: foil 1s forwards;
  }

  & .surprise-box__top {
    animation: top 1s forwards;
  }

  & .surprise-box__ball {
    animation: ball 1s forwards;
  }
}

.surprise-box__wrapper.animate-custom {
  & .surprise-box__dust {
    animation: dust-custom 1s forwards;
  }

  & .surprise-box__foil {
    animation: foil 1s forwards;
  }

  & .surprise-box__top {
    animation: top-custom 1s forwards;
  }

  & .surprise-box__ball {
    animation: ball 1s forwards;
  }
  & .surprise-box__body {
    animation: body 1s forwards;
  }
}

.surprise-box__top {
  position: absolute;
  z-index: 9;
  //left: 40px;
  left: 90px;
  top: -100px;

  @include tablets {
    left: 75px;
    top: -60px;
  }
}

.surprise-box__ball {
  position: absolute;
  z-index: 6;
  left: 125px;
  top: 18px;
  max-width: 49px;
  opacity: 0;

  @include tablets {
    left: 110px;
    max-width: 38px;
  }
}

.surprise-box__body {
  z-index: 5;
  //left: 0;
  left: 50px;
  position: absolute;
}

.surprise-box__dust {
  max-width: 220px;
  position: absolute;
  z-index: 10;
  top: -75px;
  //left: -15px;
  left: 35px;
}

.surprise-box__foil {
  position: absolute;
  z-index: 1;
  //left: -65px;
  left: -15px;
  top: -180px;

  @include tablets {
    left: 0px;
    top: -125px;
  }
}

@keyframes dust {
  100% {
    transform: translateX(-80px) scale(120%);
  }
}

@keyframes dust-custom {
  100% {
    transform: translateX(-80px) scale(140%) rotate(-10deg);
  }
}

@keyframes foil {
  100% {
    transform: scale(120%) translateY(30px) translateX(30px);
  }
}

@keyframes top {
  100% {
    transform: translateY(-45px);
  }
}

@keyframes top-custom {
  100% {
    transform: translateY(-35px) translateX(25px) rotate(10deg);
  }
}

@keyframes ball {
  50% {
    transform: scale(170%) translateY(-5px);
    opacity: 0.6;
  }
  100% {
    transform: scale(140%) translateY(-10px);
    opacity: 1;
    //z-index: 11;
  }
}

@keyframes body {
  100% {
    transform: rotate(-5deg);
  }
}
</style>
