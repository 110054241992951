import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import "bootstrap";
import VueYandexMetrika from "vue3-yandex-metrika";
import AOS from "aos";
import "aos/dist/aos.css";
import { createYmaps } from "vue-yandex-maps";

const baseUrl = "https://www.k31.ru/api";
axios.defaults.baseURL = baseUrl;
AOS.init({
  duration: 700,
  offset: 80,
});

const app = createApp(App);
app.use(VueYandexMetrika, {
  id: "95777114",
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});
app.use(
  createYmaps({
    apikey: "dfce6d46-f60d-4e8a-8e2e-3c74d334baa8",
    lang: "ru_RU",
    // enterprise: false,
    // version: "2.1",
  })
);
app.mount("#app");
