<script>
import GradientText from "@/components/ui/GradientText.vue";
import BtnLink from "@/components/ui/BtnLink.vue";

export default {
  name: "HeroSection",
  components: { BtnLink, GradientText },
};
</script>

<template>
  <section class="hero">
    <div class="hero__blur"></div>
    <div class="container hero__wrapper">
      <h1 data-aos="zoom-in" data-aos-duration="700" class="hero__title">
        <GradientText>Чудеса продолжаются</GradientText>
        <br />
        в клиниках К<span>+</span>31
      </h1>

      <p data-aos="zoom-in" data-aos-duration="700" class="hero__text">
        Мы продолжаем дарить вам волшебные подарки! Открывайте их, забирайте
        себе или отправляйте родным и близким.
      </p>

      <BtnLink href="#surprise-box-section" big class="hero__link">
        <img src="@/assets/uploads/icon/surprise.svg" alt="картинка подарка" />
        <span>Получить подарок</span>
      </BtnLink>
    </div>
  </section>
</template>

<style scoped lang="scss">
.hero {
  position: relative;
  padding-top: 200px;
  padding-bottom: 105px;
  background-image: url("~@/assets/uploads/img/bg-1980.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;

  @include tablets {
    background-image: url("~@/assets/uploads/img/bg-768.jpg");
    padding-top: 105px;
    background-size: cover;
    padding-bottom: 570px;
  }

  @include phone {
    background-image: url("~@/assets/uploads/img/bg-375.jpg");
    background-size: cover;
  }

  .container {
    max-width: 1000px;

    @include tablets {
      text-align: center;
    }
  }
}

.hero__wrapper {
  position: relative;

  &:before {
    position: absolute;
    display: block;
    content: "";
    width: 60%;
    height: 100%;
    right: -50%;
    translate: -50%;
    top: -39px;
    background-image: url("~@/assets/uploads/img/shine_animate.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform-origin: center center;
    animation: blur 3s linear infinite;

    @include tablets {
      display: none;
    }
  }
}

.hero__title {
  margin-bottom: 30px;
  color: #fff;
  font-family: Playfair Display;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 0.64px;

  @include tablets {
    text-align: center;
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 44px;
  }

  @include phone {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.32px;
  }

  & span {
    color: $main_orange;
    line-height: normal;
  }
}

.hero__text {
  margin-bottom: 90px;
  max-width: 680px;

  @include tablets {
    max-width: 100%;
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
    line-height: 26px;
  }

  @include phone {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
  }
}

.hero__link {
  max-width: 355px;
  padding-top: 16px;
  padding-bottom: 18px;

  @include tablets {
    margin: 0 auto;
    max-width: 300px;
    padding-top: 13px;
    padding-bottom: 15px;
  }
}

@keyframes blur {
  0% {
    opacity: 1;
    transform: scale(100%);
  }

  50% {
    opacity: 0.2;
    transform: scale(95%);
  }

  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

.hero__blur {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  @include tablets {
    display: none;
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 453px;
    height: 235px;
    right: 0;
    bottom: -20%;
    z-index: 0;
    border-radius: 414.244px;
    background: radial-gradient(
      61.76% 63.84% at 45.76% 48.65%,
      #370f06 0%,
      rgba(55, 15, 6, 0.59) 43.3%,
      #5e301d 100%
    );
    filter: blur(32px);
  }
}
</style>
