<script>
import { reactive, ref, toRefs, watch } from "vue";
import SurpriseBox from "@/components/SurpriseBox.vue";
import SpecialModal from "@/components/SpecialModal.vue";
import axios from "axios";

export default {
  name: "SurpriseBoxWrapper",
  components: { SpecialModal, SurpriseBox },
  props: {
    userId: {
      type: [Number, null],
      required: true,
      default: null,
    },
    promoId: {
      type: [String, null],
      required: true,
      default: null,
    },
  },
  setup(props) {
    const { userId, promoId } = toRefs(props);
    const modalIsOpen = ref(false);
    const promoInfo = ref(null);
    const surpriseList = ref(null);
    const surpriseBoxList = reactive([
      {
        title: "Cтоматология",
        boxRotate: "0deg",
        type: "stom",
        titleDown: true,
        imageLeft: false,
      },
      {
        title: "Косметология",
        boxRotate: "0deg",
        type: "cosm",
        titleDown: false,
        imageLeft: true,
      },
      {
        title: "Чек-апы",
        boxRotate: "10deg",
        type: "check",
        titleDown: true,
        imageLeft: true,
      },
      {
        title: "Годовые программы",
        boxRotate: "0deg",
        type: "annl",
        titleDown: false,
        imageLeft: false,
      },
    ]);
    const openModal = async (type, promoId = null) => {
      try {
        let params = new URLSearchParams({
          type: type,
          uid: userId.value,
        }).toString();
        if (promoId) {
          params = new URLSearchParams({
            promo_id: promoId,
            uid: userId.value,
          }).toString();
        }

        const response = await axios(`/v1/ny/promo/?${params}`);
        if (response.data.status === "ok") {
          promoInfo.value = response.data.data;
          modalIsOpen.value = true;

          if (promoId) {
            surpriseList.value.scrollIntoView({
              block: "center",
            });
          }
          document.body.style.overflow = "hidden";
          return;
        }
        promoInfo.value = null;
      } catch (err) {
        promoInfo.value = null;
      }
    };

    watch(
      promoId,
      async () => {
        if (promoId.value) {
          await openModal(null, promoId.value);
        }
      },
      { immediate: true }
    );

    const closeModal = () => {
      modalIsOpen.value = false;
      document.body.style.overflow = "unset";
    };

    return {
      surpriseBoxList,
      modalIsOpen,
      openModal,
      promoInfo,
      closeModal,
      surpriseList,
    };
  },
};
</script>

<template>
  <SpecialModal
    @closeModal="closeModal"
    :userId="userId"
    :modalIsOpen="modalIsOpen"
    :promoInfo="promoInfo"
  />
  <div class="surprise">
    <div class="container">
      <div ref="surpriseList" class="surprise__list">
        <img
          src="@/assets/uploads/img/surprise-box-2.png"
          alt="подарочная коробка"
          class="surprise__picture"
        />
        <SurpriseBox
          @click.capture="openModal(box.type)"
          v-for="(box, ix) in surpriseBoxList"
          :key="ix"
          :title="box.title"
          :titleDown="box.titleDown"
          :boxRotate="box.boxRotate"
          :imageLeft="box.imageLeft"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.surprise {
  position: relative;
  //padding-bottom: 90px;
  padding-bottom: 130px;
  background-image: url("~@/assets/uploads/img/bg-surprise.png");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;

  @include tablets {
    background-image: unset;
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 408px;
    height: 408px;
    left: 10%;
    top: -20%;
    z-index: -1;
    background-image: url("~@/assets/uploads/img/surprise-straz.png");
    background-repeat: no-repeat;
    background-position: center;

    @include tablets {
      top: 15%;
      left: 20%;
    }

    @include phone {
      top: -1%;
    }
  }
}

.container {
}

.surprise__list {
  display: flex;
  gap: 5px;
  transform: translateY(80px);

  @include tablets {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  @include phone {
    grid-template-columns: 1fr;
    gap: 12px;
    transform: translateY(0);
  }
}

.surprise__picture {
  display: none;

  @include phone {
    display: block;
  }
}
</style>
