<template>
  <div class="block-landing-call">
    <div class="container site-container">
      <div class="row">
        <div class="col-xs-12 col-lg-6">
          <div class="block-landing-call__title">
            Не нашли нужной информации или услуги?
          </div>
        </div>
        <div class="col-xs-12 col-lg-6">
          <div class="block-landing-call__links">
            <div class="block-landing-call__item">
              <div class="block-landing-call__item-icon">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M15.0931 21C14.4152 21 13.7431 20.8802 13.0858 20.6414C10.1903 19.5895 7.5027 17.8764 5.31338 15.6871C3.12405 13.4978 1.4109 10.8101 0.359096 7.91463C0.0211004 6.9841 -0.0786455 6.02355 0.0627295 5.05956C0.195164 4.1566 0.54493 3.28001 1.07426 2.52453C1.60592 1.76573 2.31472 1.13461 3.12401 0.699411C3.98703 0.235338 4.92899 0 5.92383 0C6.23324 0 6.50065 0.216185 6.56549 0.518703L7.59551 5.3255C7.64215 5.54316 7.57525 5.76964 7.41788 5.92705L5.65785 7.68704C7.31818 10.9881 10.0123 13.6822 13.3134 15.3425L15.0733 13.5825C15.2308 13.4251 15.4573 13.3583 15.6749 13.4049L20.4817 14.4349C20.7842 14.4997 21.0004 14.7672 21.0004 15.0766C21.0004 16.0714 20.7651 17.0134 20.3009 17.8764C19.8657 18.6857 19.2346 19.3945 18.4758 19.9262C17.7204 20.4555 16.8438 20.8053 15.9408 20.9377C15.6578 20.9792 15.3749 21 15.0931 21ZM5.39951 1.34116C4.08726 1.48602 2.92255 2.17382 2.14911 3.27771C1.27937 4.519 1.07655 6.04574 1.59267 7.46655C3.61482 13.0332 7.96727 17.3856 13.5339 19.4078C14.9547 19.9239 16.4814 19.7211 17.7228 18.8514C18.8267 18.0779 19.5145 16.9132 19.6593 15.601L15.749 14.7631L13.9109 16.6011C13.7153 16.7967 13.4181 16.8487 13.1679 16.7311C9.26376 14.8969 6.10355 11.7367 4.26941 7.8326C4.15182 7.58229 4.20374 7.2851 4.39934 7.08955L6.23742 5.25147L5.39951 1.34116Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="21" height="21" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="block-landing-call__item-content">
                <div class="block-landing-call__item-text">Позвоните нам!</div>
                <a href="tel:+74951045117" class="block-landing-call__item-link"
                  >+7 (495) 104-51-17</a
                >
              </div>
            </div>
            <div class="block-landing-call__item">
              <div class="block-landing-call__item-icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.63678 21.5026L2.10627 16.2834C1.1613 14.6781 0.663769 12.8515 0.663769 10.9752C0.663769 5.19774 5.36405 0.497467 11.1415 0.497467C16.9189 0.497467 21.6191 5.19774 21.6191 10.9752C21.6191 16.7526 16.9189 21.4529 11.1415 21.4529C9.34126 21.4529 7.57851 20.9921 6.0191 20.1171L0.63678 21.5026ZM6.29427 18.2109L6.61498 18.4067C7.9746 19.2367 9.53987 19.6755 11.1415 19.6755C15.9388 19.6755 19.8417 15.7725 19.8417 10.9752C19.8417 6.17779 15.9388 2.27483 11.1415 2.27483C6.3441 2.27483 2.44113 6.17779 2.44113 10.9752C2.44113 12.6467 2.91622 14.27 3.81491 15.6694L4.03089 16.0058L3.18467 19.0113L6.29427 18.2109Z"
                    fill="white"
                  />
                  <path
                    d="M8.19814 6.09853L7.51827 6.06147C7.30472 6.04982 7.09525 6.12118 6.93384 6.26139C6.60424 6.54762 6.07722 7.10097 5.91535 7.82205C5.67396 8.89723 6.04701 10.2138 7.01251 11.5304C7.97801 12.8469 9.77729 14.9534 12.959 15.8531C13.9842 16.143 14.7908 15.9476 15.413 15.5495C15.9059 15.2343 16.2456 14.7282 16.3681 14.1562L16.4766 13.6492C16.5111 13.488 16.4293 13.3245 16.2796 13.2555L13.9819 12.1964C13.8327 12.1277 13.6558 12.1711 13.5555 12.3011L12.6535 13.4705C12.5853 13.5589 12.4687 13.5939 12.3634 13.5569C11.7456 13.3398 9.67638 12.4732 8.54097 10.2861C8.49173 10.1912 8.50398 10.0761 8.57382 9.99518L9.43591 8.99788C9.52399 8.89604 9.54623 8.75286 9.49331 8.62911L8.50286 6.31188C8.45013 6.18852 8.33197 6.10584 8.19814 6.09853Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="block-landing-call__item-content">
                <div class="block-landing-call__item-text">
                  Напишите в whats app
                </div>
                <a
                  href="https://api.whatsapp.com/send?phone=79162591131&text=&source=&data=&app_absent="
                  class="block-landing-call__item-link"
                  >Задать вопрос</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallSection",
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.block-landing-call__links {
  border-radius: 36px;
  background: #1e1714;
  padding: 40px 30px;
  @media only screen and (max-width: 559px) {
    padding: 24px 30px;
  }
}

.block-landing-call__item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 991px) {
    justify-content: center;
  }
  @media only screen and (max-width: 559px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.block-landing-call__item-text {
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  color: #e1e8f4;
  margin-right: 16px;
  @media (max-width: 1130px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 559px) {
    font-size: 16px;
  }
}

.block-landing-call__item-link {
  font-weight: 400;
  font-size: 20px;
  color: #e1e8f4;
  opacity: 0.5;
  transition: 0.2s;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    opacity: 0.6;
  }

  @media only screen and (max-width: 559px) {
    font-size: 16px;
  }
}

.block-landing-call__item-content {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 559px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.block-landing-call__item-icon {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  @media only screen and (max-width: 559px) {
    margin-right: 16px;
  }
  svg {
    display: flex;
  }
}

.block-landing-call__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: #3f210c;
  text-align: left;
  font-family: Playfair Display, serif;
  letter-spacing: 0.32px;
  @media only screen and (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 32px;
    text-align: center;
  }
  @media only screen and (max-width: 559px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.block-landing-call {
  background: #c69245;
  padding: 36px 0;

  .row {
    align-items: center;
  }
}
</style>
