<template>
  <div class="form-group" :class="{ 'form-group--error': validError }">
    <div class="form-group__top">
      <label v-if="labelTitle" :for="nameId">{{ labelTitle }}</label>
      <div v-if="validError" class="error help-block">
        {{ errorMsg }}
      </div>
    </div>
    <input
      :disabled="disabled"
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value.trim())"
      :placeholder="placeholder"
      class="form-group__input"
      :class="{
        'validation-error': validError,
        disabled: disabled,
      }"
      :name="nameId"
      autocomplete="on"
      :pattern="pattern"
      :maxlength="maxlength"
    />
  </div>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    validError: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMsg: {
      type: String,
      required: false,
      default: "Необходимо заполнить поле.",
    },
    modelValue: {
      type: [String, null],
      required: true,
      default: null,
    },
    pattern: {
      type: [String, null],
      required: false,
      default: null,
    },
    nameId: {
      type: String,
      required: true,
    },
    labelTitle: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
      default: 50,
    },
  },
};
</script>

<style scoped lang="scss">
.form-group {
  display: flex;
  gap: 5px;
  flex-direction: column;

  &__input {
    padding: 12px;
    background-color: #ffffff;
    border: 1px solid #bac7de;
    border-radius: 8px;
    color: #111;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    &:focus {
      border: 1px solid #002856;
    }

    &::placeholder {
      color: #111;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      color: #062e4f;
      font-size: 16px;
      font-weight: 350;
    }
  }
}

.help-block.error {
  font-size: 12px;
  color: #ff1f00;
}

.validation-error {
  border-color: #ff1f00;
  color: #ff1f00;

  &:focus {
    color: green;
  }
}

.validation-error::placeholder {
  color: #ff1f00;
}
</style>
