<template>
  <div class="preload"></div>
  <div
    class="modal modal-spec"
    ref="modalTrack"
    tabindex="-1"
    :class="{ show: modalIsOpen, 'd-block': modalIsOpen }"
    role="dialog"
    aria-hidden="true"
    id="surprise-box"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-spec__toils">
          <picture>
            <source
              srcset="@/assets/uploads/img/bg-tois-375.png"
              media="(max-width: 600px)"
            />
            <source
              srcset="@/assets/uploads/img/bg-tois-768.png"
              media="(max-width: 990px)"
            />
            <img
              :class="{ active: modalIsOpen }"
              src="@/assets/uploads/img/bg-tois-1440.png"
              alt="новогодние украшения"
              class="modal-spec__toil"
            />
          </picture>
        </div>
        <div class="modal-container">
          <div class="modal-body">
            <div class="modal-center">
              <div class="modal-spec__top">
                <button
                  @click="closeModal"
                  class="modal-spec__close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="55"
                    height="55"
                    viewBox="0 0 55 55"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="54"
                      height="54"
                      rx="27"
                      stroke="white"
                    />
                    <path
                      d="M35 26.5H18M18 26.5L26.0526 18M18 26.5L26.0526 35"
                      stroke="white"
                    />
                  </svg>
                  <span>Вернуться на главную</span>
                </button>
              </div>
              <div class="container modal__container">
                <div v-show="previewShow" class="modal-spec__preview">
                  <h2 class="modal-spec__title">
                    <GradientText>Поздравляем!</GradientText>
                  </h2>
                  <div class="modal-spec__middle">
                    <div class="modal-spec__left-column">
                      <SurpriseBoxAnime :start-animation="modalIsOpen" />
                    </div>
                    <div class="modal-spec__right-column">
                      <h5 class="modal-spec__subtitle upper">Ваш подарок:</h5>
                      <div v-html="title" class="modal-spec__subtitle"></div>
                      <div class="modal-spec__text desck">
                        <p v-html="promoInfo?.preview"></p>
                        <p v-html="promoInfo?.description"></p>
                        <p v-html="promoInfo?.addon"></p>
                        <a :href="promoInfo?.url" target="_blank">Подробнее</a>
                      </div>
                    </div>
                  </div>

                  <div class="modal-spec__bottom">
                    <BtnLink @click.prevent="takeYourself" class="hero__link">
                      <span>Классно! Забрать подарок себе!</span>
                    </BtnLink>
                    <BtnLink @click.prevent="sendToVisible" class="hero__link">
                      <span>Отправить родным и друзьям</span>
                    </BtnLink>
                    <BtnLink
                      @click="closeModal"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      white
                      class="hero__link"
                    >
                      <span>Хочу сыграть еще!</span>
                    </BtnLink>
                  </div>

                  <div class="modal-spec__desc">
                    <div class="modal-spec__text">
                      <p v-html="promoInfo?.preview"></p>
                      <p v-html="promoInfo?.description"></p>
                      <p v-html="promoInfo?.addon"></p>
                      <a :href="promoInfo?.url" target="_blank">Подробнее</a>
                    </div>
                  </div>
                </div>
                <FormTakeYourself
                  v-show="formTakeYourselfVisible"
                  :modalIsOpen="modalIsOpen"
                  :message="title"
                  @closeForm="closeForm"
                  :userId="userId"
                  :promoInfo="promoInfo"
                />
                <FormSendTo
                  v-show="formSendToVisible"
                  @closeForm="closeForm"
                  :promoInfo="promoInfo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GradientText from "@/components/ui/GradientText.vue";
import BtnLink from "@/components/ui/BtnLink.vue";
import SurpriseBoxAnime from "@/components/SurpriseBoxAnime.vue";
import { computed, ref, toRefs } from "vue";
import FormTakeYourself from "@/components/form/FormTakeYourself.vue";
import FormSendTo from "@/components/form/FormSendTo.vue";

export default {
  components: {
    FormSendTo,
    FormTakeYourself,
    SurpriseBoxAnime,
    BtnLink,
    GradientText,
  },
  emits: ["closeModal"],
  props: {
    modalIsOpen: {
      type: Boolean,
      require: true,
    },
    promoInfo: {
      type: [Object, null],
      required: true,
      default: null,
    },
    userId: {
      type: [Number, null],
      required: true,
      default: null,
    },
  },
  name: "SpecialModal",
  setup(props, { emit }) {
    const { promoInfo } = toRefs(props);
    const previewShow = ref(true);
    const formTakeYourselfVisible = ref(false);
    const formSendToVisible = ref(false);
    const takeYourself = () => {
      formTakeYourselfVisible.value = true;
      previewShow.value = false;
    };

    const sendToVisible = () => {
      formSendToVisible.value = true;
      previewShow.value = false;
    };

    const closeModal = () => {
      previewShow.value = true;
      formTakeYourselfVisible.value = false;
      formSendToVisible.value = false;
      emit("closeModal");
    };

    const title = computed(() => {
      if (!promoInfo.value) return "";

      return promoInfo.value.title;
    });

    const closeForm = () => {
      formTakeYourselfVisible.value = false;
      formSendToVisible.value = false;
      previewShow.value = true;
    };

    return {
      previewShow,
      formTakeYourselfVisible,
      formSendToVisible,
      takeYourself,
      closeModal,
      sendToVisible,
      title,
      closeForm,
    };
  },
};
</script>
<style>
.modal-spec__text ul {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  li {
    position: relative;
    padding-left: 15px;
    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #fff;
    }
  }
}
</style>
<style scoped lang="scss">
.preload {
  display: block;
  background-image: url("~@/assets/uploads/img/modal-bg.jpg");
  visibility: hidden;
  z-index: -1;
  width: 0;
  height: 0;
}
.modal__container {
  @include phone {
    padding: 0;
  }
}
@keyframes up {
  0% {
    transform: translateY(65px);
  }
  100% {
    transform: translateY(0);
  }
}

.modal-spec__toil {
  width: 100%;
  position: absolute;
  bottom: 0;

  &.active {
    animation: up 1s ease-in-out forwards;
  }
}

.modal-spec__toil.left {
  transform: translateY(95px);
  left: 0;

  @include tablets {
    max-width: 550px;
  }
}

.modal-spec__toil.left.active {
  animation: left-toil 0.5s ease-in-out forwards;
}

.active {
}

.modal-spec__toil.right {
  right: 0;
  @include tablets {
    max-width: 430px;
  }
}

.modal-spec__toil.right.active {
  animation: right-toil 0.5s ease-in-out forwards;
}

@keyframes left-toil {
  0% {
    transform: translateY(95px);
  }
  100% {
    transform: translateY(45px);
  }
}
@keyframes right-toil {
  0% {
    transform: translateY(84px);
  }
  100% {
    transform: translateY(0);
  }
}

.modal-content {
  border: 0;
  height: 100%;
  border-radius: 0;
  background-image: url("~@/assets/uploads/img/modal-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  position: relative;
  //height: max-content;
  overflow: hidden;

  @include tablets {
    //height: max-content;
    //overflow-y: scroll;
  }
}
.modal-body {
  @include tablets {
    padding-bottom: 150px;
  }
}
.modal-dialog {
  height: 100%;
  margin: 0;
  max-width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(max-content, 100%);
}

.modal-spec__top {
  margin-bottom: 20px;
  padding-left: 3%;
}
.modal-spec__close {
  display: flex;
  align-items: center;
  gap: 16px;
  border: unset;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.54px;

  @include tablets {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    & svg {
      max-width: 48px;
      max-height: 48px;
    }
  }

  &:hover {
    transform: translateX(-20px);
    opacity: 0.8;
  }
}

.modal-spec__title {
  margin-bottom: 65px;
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.48px;

  @include tablets {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
  }
}
.modal-spec__subtitle {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;

  @include tablets {
    font-size: 18px;
    line-height: 22px;
  }

  &.upper {
    text-transform: uppercase;
  }

  & span {
    text-decoration: line-through;
  }
}
.modal-spec__text {
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: none;

  @include tablets {
    display: flex;
  }

  &.desck {
    display: flex;
    @include tablets {
      display: none;
    }
  }

  @include tablets {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &:deep(a) {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-decoration-line: underline;
  }
}

.modal-spec__middle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  margin-bottom: 100px;

  @include tablets {
    margin-bottom: 200px;
    gap: 130px;
    justify-items: center;
    grid-template-columns: 1fr;
  }
}

.modal-spec__left-column {
  width: 100%;
  justify-self: end;
  display: flex;
  justify-content: flex-end;

  @include tablets {
    grid-row-start: 2;
    justify-content: center;
  }
}

.modal-spec__right-column {
  display: flex;
  flex-direction: column;
  gap: 7px;
  max-width: 570px;
  width: 100%;

  @include tablets {
    text-align: center;
  }
}

.modal-spec__bottom {
  display: flex;
  gap: 24px;

  @include tablets {
    align-items: center;
    margin-bottom: 34px;
    gap: 18px;
    flex-direction: column;
  }
}
.modal-spec__desc {
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    max-width: 360px;
  }
}

.hero__link {
  @include tablets {
    max-width: 360px;
  }
}
</style>
