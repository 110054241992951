<script>
export default {
  name: "SurpriseBox",
  props: {
    title: String,
    titleDown: Boolean,
    boxRotate: String,
    imageLeft: Boolean,
  },
};
</script>

<template>
  <button class="box" type="button">
    <img
      v-if="imageLeft"
      src="@/assets/uploads/img/surprise-box.png"
      alt="подарочная коробка"
      class="box__picture"
    />
    <img
      v-else
      src="@/assets/uploads/img/surprise-box-2.png"
      alt="подарочная коробка"
      class="box__picture"
    />
    <span class="box__title" :class="{ down: titleDown, up: !titleDown }">
      <span>
        {{ title }}
      </span>
    </span>
  </button>
</template>

<style scoped lang="scss">
.box {
  position: relative;
  cursor: pointer;
  display: block;
  border: unset;

  &:first-child {
    transform: translateY(-60px);

    @include phone {
      transform: translateY(0);
    }
  }

  &:nth-child(2) {
    @include tablets {
      transform: translateY(-70px);
    }

    @include phone {
      transform: translateY(0);
    }
  }

  &:last-child {
    @include tablets {
      transform: translateY(80px);
    }

    @include phone {
      transform: translateY(0);
    }
  }

  @include phone {
    width: 100%;
  }

  &:hover img {
    transform: translateY(-15px);
  }
}

.box__picture {
  position: relative;
  z-index: 5;
  max-width: 319px;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  rotate: v-bind(boxRotate);

  @include phone {
    display: none;
  }
}

.box__title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 254px;
  max-height: 80px;
  height: 100%;
  width: 100%;
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  border-radius: 12px;
  border: 4px solid #ad6614;
  background: linear-gradient(
    180deg,
    #fff 18.75%,
    #c2c1bd 71.25%,
    #a9947c 92.5%
  );

  @include phone {
    width: 100%;
    padding: 22px 5px;
    font-size: 24px;
    line-height: 24px;
  }

  @include phone {
    max-width: 100%;
    max-height: 70px;
    position: static;
  }
}

.box__title.down {
  bottom: 0;
  z-index: 10;
  transform: translateX(-50%) translateY(35%);

  @include phone {
    transform: translate(0);
  }
}

.box__title.up {
  top: 0;
  transform: translateX(-50%) translateY(-50%);

  @include phone {
    transform: translate(0);
  }
}

.box__title span {
  display: block;
  background: linear-gradient(
    90deg,
    #1d0b05 23.75%,
    #5f5f5f 41.57%,
    #100505 65.45%,
    #35261d 90.96%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
