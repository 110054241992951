<script>
import GradientText from "@/components/ui/GradientText.vue";
import SurpriseBoxWrapper from "@/components/SurpriseBoxWrapper.vue";

export default {
  name: "SpecialSection",
  components: { SurpriseBoxWrapper, GradientText },
  props: {
    userId: {
      type: [Number, null],
      required: true,
      default: null,
    },
    promoId: {
      type: [String, null],
      required: true,
      default: null,
    },
  },
};
</script>

<template>
  <section class="special">
    <div class="container">
      <div class="special__wrapper">
        <div class="special__right">
          <img
            src="@/assets/uploads/img/special-right.png"
            alt="ёлочное украшение шарик"
            data-aos="zoom-in"
            data-aos-duration="700"
          />
        </div>
        <div class="special__pictures">
          <div class="special__cone">
            <img
              src="@/assets/uploads/img/specil-cone.png"
              alt="ёлочное украшение шишка"
              data-aos="zoom-in"
              data-aos-duration="700"
            />
          </div>
          <div class="special__ball">
            <img
              src="@/assets/uploads/img/special-ball.png"
              alt="ёлочное украшение шарик"
              data-aos="zoom-in"
            />
          </div>
        </div>
        <div class="special__blur"></div>
        <h2 data-aos="zoom-in" class="special__title">
          <GradientText>Раз, два, три!</GradientText>
          Подарочек бери!
        </h2>
        <ul class="special__list">
          <li class="special__item">
            <img src="@/assets/uploads/img/star.png" alt="звездочка" />
            <p>
              Кликните на любую из подарочных коробочек и получите Ваш чудесный
              презент! Но это еще не все сюрпризы.
            </p>
          </li>
          <li class="special__item">
            <img src="@/assets/uploads/img/star.png" alt="звездочка" />
            <p>
              Открывая коробочки, Вы можете забрать подарок себе или поделиться
              им со своими друзьями, родными, любимыми и близкими людьми.
            </p>
          </li>
        </ul>
      </div>
    </div>

    <div id="surprise-box-section"></div>
    <SurpriseBoxWrapper :userId="userId" :promoId="promoId" />
  </section>
</template>

<style scoped lang="scss">
.special {
  overflow: hidden;
  background: linear-gradient(135deg, #0a0603 7.4%, #3a170c 47.22%);
}

.container {
}

.special__wrapper {
  margin-bottom: 50px;
  padding: 22px 95px 36px 95px;
  position: relative;
  z-index: 3;
  border-radius: 24px;
  border: 3px solid #58311a;
  background: linear-gradient(110deg, #060502 28.16%, #070603 83.04%);

  @include tablets {
    padding-top: 28px;
    padding-bottom: 88px;
  }

  @include phone {
    padding: 28px 7px 100px 7px;
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 614px;
    height: 326px;
    left: 12%;
    top: 26%;
    z-index: -1;
    border-radius: 613.627px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #451c0b 0%,
      rgba(44, 16, 5, 0.36) 53.22%,
      rgba(52, 20, 8, 0) 100%
    );

    filter: blur(32px);

    @include tablets {
      display: none;
    }
  }

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 453px;
    height: 235px;
    right: -20%;
    top: 27%;
    z-index: -1;
    border-radius: 453.148px;
    background: radial-gradient(
      71.56% 77.31% at 53.48% 39.86%,
      #522a0f 0%,
      rgba(55, 20, 11, 0.5) 48.06%,
      rgba(40, 22, 10, 0) 100%
    );
    filter: blur(32px);

    @include tablets {
      display: none;
    }
  }
}
.special__blur {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 596px;
    height: 293px;
    left: -31%;
    top: 13%;
    z-index: -1;
    border-radius: 596.473px;
    background: radial-gradient(
      53.45% 52.49% at 61.97% 48.89%,
      #692622 0%,
      rgba(51, 16, 7, 0.62) 42.19%,
      rgba(36, 22, 13, 0) 100%
    );
    filter: blur(32px);

    @include tablets {
      display: none;
    }
  }
}

.special__pictures {
  position: absolute;
  left: -5%;
  top: 50%;
  z-index: 4;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;

  @include tablets {
    flex-direction: row;
    top: unset;
    bottom: 0;
    gap: 20px;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
  }
}

.special__ball {
  transform: translateX(70px);
  @include tablets {
    transform: translateX(0) rotate(6deg) translateY(10px);
  }
  & img {
    max-width: 100px;

    @include tablets {
      max-width: 80px;
      max-height: 90px;
    }
  }
}
.special__cone {
  transform: rotate(-16deg);

  & img {
    max-width: 145px;
    @include tablets {
      max-width: 130px;
    }
  }
}

.special__right {
  position: absolute;
  right: -210px;
  top: 56%;
  z-index: 4;
  transform: translateY(-50%);

  @include tablets {
    display: none;
  }
}

.special__title {
  margin-bottom: 50px;
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 0.48px;

  @include tablets {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 30px;
  }

  @include phone {
    max-width: 288px;
    margin: 0 auto 16px auto;
  }
}

.special__list {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.44px;

  @include tablets {
    font-size: 16px;
    line-height: 20px;
  }

  & img {
    @include tablets {
      max-width: 50px;
    }
  }
}

.special__item {
  max-width: 820px;
  display: flex;
  align-items: center;
  gap: 6px;
  @include tablets {
    align-items: start;
  }

  &:not(:last-child) {
    margin-bottom: 26px;

    @include tablets {
      margin-bottom: 22px;
    }
  }

  &:last-child {
    margin-bottom: 35px;

    @include tablets {
      margin-bottom: 12px;
    }
  }
}

.special__subtitle {
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 0.32px;

  @include tablets {
    font-size: 20px;
    line-height: 24px;
  }
  @include phone {
    margin: 0 auto;
    max-width: 330px;
  }
}
</style>
