<template>
  <div class="checkbox" :class="{ disabled: disabled, white: white }">
    <div class="checkbox__rules">
      <input
        :disabled="disabled"
        class="checkbox-input"
        type="checkbox"
        :id="id"
        :value="value"
        v-model="proxyModel"
      />
      <label :for="id" class="checkbox__label"> </label>
      <div>
        <span> {{ label }}&nbsp;</span>
        <a
          v-if="href"
          @click="stopEventIfDisabled"
          target="_blank"
          :href="href"
        >
          {{ linkLabel }}
        </a>
      </div>
    </div>
    <div v-if="validError" class="error help-block help-block-error">
      Необходимо дать согласие
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";

export default {
  name: "FormInputCheckbox",
  props: {
    id: {
      type: [String, Number],
      required: true,
      defualt: "",
    },
    modelValue: {
      type: [Boolean, Number, Array],
      required: true,
      default: false,
    },
    validError: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    linkLabel: {
      type: String,
      required: false,
      default: "",
    },
    href: {
      type: String,
      required: false,
      default: "",
    },
    white: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },

  setup(props, { emit }) {
    const { disabled, modelValue } = toRefs(props);
    const stopEventIfDisabled = (event) => {
      if (disabled.value) {
        event.preventDefault();
      }
    };

    const proxyModel = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit("update:modelValue", val);
      },
    });

    return {
      stopEventIfDisabled,
      proxyModel,
    };
  },
};
</script>

<style scoped lang="scss">
.checkbox {
  color: #222;
  font-size: 16px;
  line-height: 100%;

  @include phone {
    font-size: 14px;
  }

  &__rules {
    color: black;
    margin-bottom: 8px;
    line-height: 16px;
  }
}

.checkbox__rules {
  display: flex;
}

.checkbox.white .checkbox__rules {
  color: white;
}

.checkbox a {
  text-decoration: underline;
}

.checkbox.white a {
  color: white;
  text-decoration: underline;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  padding-right: 10px;
  position: relative;
  cursor: pointer;
}

.checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #808080;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox.white label:before {
  background-color: white;
}

.checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  left: 5px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid #58311a;
  border-radius: 1px;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox.disabled {
  & a {
    color: #bac7de;
  }

  & label {
    color: #bac7de;
  }

  color: #bac7de;
}

.checkbox.disabled input:checked + label:after {
  border: solid #bac7de;
  border-radius: 1px;
  border-width: 0 3px 3px 0;
}
</style>
