<template>
  <footer class="footer__wrapper">
    <div class="footer">
      <div class="container site-container">
        <p class="footer__text">
          Уважаемые пациенты! Для записи на прием воспользуйтесь онлайн-формой
          сайта или позвоните в колл-центр (телефон
          <a href="tel:+74951045105">+7 (495) 104-51-05</a>). При посещении
          стационара не забудьте взять с собой документы, подтверждающие
          личность.
        </p>
        <div class="row">
          <div class="col-md-2">
            <div class="footer__logo">
              <a href="#" class="logo">
                <img alt="logo" src="@/assets/uploads/logo.svg" />
              </a>
            </div>
          </div>
          <div class="col-md-10">
            <div class="footer__top">
              <div class="row">
                <div class="col-xs-6 col-md-4 col-lg-2">
                  <div class="footer__nav">
                    <nav>
                      <ul>
                        <li>
                          <a href="https://www.k31.ru/service/">Отделения</a>
                        </li>
                        <li>
                          <a href="https://www.k31.ru/specialisty/">Врачи</a>
                        </li>
                        <li>
                          <a href="https://www.k31.ru/price/">Стоимость</a>
                        </li>
                        <li>
                          <a href="https://www.k31.ru/program/">Программы</a>
                        </li>
                        <li>
                          <a href="https://www.k31.ru/info/promo/">Акции</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div class="col-xs-6 col-md-4 col-lg-2">
                  <div class="footer__nav">
                    <nav>
                      <ul>
                        <li>
                          <a href="https://www.k31.ru/patient/">Пациентам</a>
                        </li>
                        <li>
                          <a href="https://www.k31.ru/info/about/"
                            >О клиниках</a
                          >
                        </li>
                        <li>
                          <a href="https://www.k31.ru/contacts/">Контакты</a>
                        </li>
                        <li>
                          <a href="https://www.k31.ru/patient/pay/element/"
                            >Онлайн-оплата</a
                          >
                        </li>
                        <li><a href="https://lk.k31.ru">Личный кабинет</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div class="col-xs-6 col-md-4 col-lg-3">
                  <div class="footer__nav">
                    <nav>
                      <ul>
                        <li>
                          <a href="https://www.k31.ru/info/license/"
                            >Лицензии К+31</a
                          >
                        </li>
                        <li>
                          <a href="https://www.k31.ru/service/travmpunkt/"
                            >Травмпункт</a
                          >
                        </li>
                      </ul>
                    </nav>
                    <a
                      href="tel:+74951044814"
                      class="contacts-item contacts-call"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_11090_27806"
                          style="mask-type: luminance"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="16"
                          height="16"
                        >
                          <path d="M0 0H16V16H0V0Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_11090_27806)">
                          <path
                            d="M0.625 8L8 0.625L15.375 8"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.85352 6.77081V15.375H14.1452V6.77081"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.54102 15.375V10.4583H10.4577V15.375"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>
                      Вызов на дом
                    </a>
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="footer__address">
                    <div class="footer__address-item">
                      <div class="footer__address-name">К+31 Лобачевского</div>
                      <div class="footer__address-text">
                        <p>ул. Лобачевского, 42с4</p>
                        <a href="tel:+74951045117">+7 (495) 104-51-17</a>
                      </div>
                    </div>
                    <div class="footer__address-item">
                      <div class="footer__address-name">
                        К+31 Петровские Ворота
                      </div>
                      <div class="footer__address-text">
                        <p>1-й Колобовский переулок, д. 4.</p>
                        <a href="tel:+74951045117">+7 (495) 104-51-17</a>
                      </div>
                    </div>
                    <div class="footer__address-item">
                      <div class="footer__address-name">К+31 Запад</div>
                      <div class="footer__address-text">
                        <p>yл. Оршанская, 16, стр. 2</p>
                        <a href="tel:+74951045117">+7 (495) 104-51-17</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer__socials-row">
              <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-md-7 col-xs-12">
                  <div class="footer__socials">
                    <div class="footer__socials-left">
                      <ul class="socials">
                        <li class="socials__item">
                          <a
                            href="https://vk.com/clinic31"
                            class="socials__link"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="8"
                                fill="#C69245"
                              />
                              <path
                                d="M19.6332 8.60948C19.7449 8.25786 19.6332 8 19.1046 8H17.3535C16.908 8 16.7044 8.22307 16.5926 8.46807C16.5926 8.46807 15.7023 10.5241 14.4407 11.8573C14.0335 12.2452 13.8475 12.3677 13.6255 12.3677C13.5145 12.3677 13.3532 12.2452 13.3532 11.8935V8.60948C13.3532 8.18753 13.2247 8 12.8534 8H10.1019C9.82401 8 9.65633 8.19509 9.65633 8.38111C9.65633 8.78037 10.2871 8.87263 10.3518 9.99782V12.4387C10.3518 12.9734 10.2504 13.0709 10.0268 13.0709C9.43356 13.0709 7.98993 11.0073 7.13318 8.64502C6.9663 8.18602 6.79782 8.00076 6.35068 8.00076H4.60045C4.09981 8.00076 4 8.22383 4 8.46883C4 8.90892 4.59326 11.0867 6.76349 13.967C8.21031 15.9338 10.2472 17 12.1028 17C13.2151 17 13.3524 16.7633 13.3524 16.355V14.8683C13.3524 14.395 13.4586 14.2997 13.8107 14.2997C14.0694 14.2997 14.515 14.4237 15.5538 15.3712C16.7403 16.4949 16.9367 16.9992 17.6035 16.9992H19.3537C19.8535 16.9992 20.1034 16.7626 19.9597 16.2952C19.8024 15.8302 19.2355 15.1534 18.4834 14.3526C18.0745 13.8959 17.4637 13.4044 17.2777 13.1586C17.0182 12.8418 17.0932 12.7019 17.2777 12.4206C17.2785 12.4214 19.4112 9.57663 19.6332 8.60948Z"
                                fill="#1E1714"
                              />
                            </svg>
                          </a>
                        </li>
                        <li class="socials__item">
                          <a
                            href="https://t.me/clinic_k31"
                            class="socials__link"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="8"
                                fill="#C69245"
                              />
                              <path
                                d="M18.055 7.0789L5.65371 11.9664C4.80738 12.3138 4.81227 12.7964 5.49844 13.0115L8.68235 14.0267L16.049 9.27639C16.3973 9.05979 16.7156 9.17631 16.454 9.41364L10.4855 14.9188H10.4841L10.4855 14.9195L10.2659 18.2737C10.5877 18.2737 10.7296 18.1228 10.9101 17.9448L12.4566 16.4079L15.6734 18.8363C16.2665 19.1701 16.6925 18.9985 16.8401 18.2751L18.9517 8.10402C19.1679 7.2183 18.6209 6.81726 18.055 7.0789Z"
                                fill="#1E1714"
                              />
                            </svg>
                          </a>
                        </li>
                        <li class="socials__item">
                          <a
                            href="https://api.whatsapp.com/send?phone=79162591131&text=&source=&data=&app_absent="
                            class="socials__link"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="25"
                                height="25"
                                rx="8"
                                fill="#C69245"
                              />
                              <path
                                d="M18.601 6.32605C17.8675 5.58573 16.9939 4.99879 16.0313 4.59945C15.0687 4.20012 14.0362 3.99636 12.994 4.00005C8.627 4.00005 5.068 7.55805 5.064 11.926C5.064 13.325 5.43 14.686 6.121 15.891L5 20L9.204 18.898C10.367 19.531 11.6699 19.8627 12.994 19.863H12.998C17.366 19.863 20.924 16.305 20.928 11.933C20.929 10.8911 20.7238 9.85924 20.3243 8.89693C19.9247 7.93462 19.3387 7.06088 18.6 6.32605H18.601ZM12.994 18.521C11.813 18.5214 10.6537 18.2036 9.638 17.601L9.398 17.457L6.904 18.111L7.57 15.6781L7.414 15.427C6.75381 14.3774 6.40465 13.1621 6.407 11.922C6.407 8.29605 9.364 5.33805 12.998 5.33805C13.8637 5.3365 14.7212 5.50632 15.521 5.83774C16.3208 6.16916 17.0471 6.65561 17.658 7.26905C18.271 7.8801 18.757 8.60647 19.0879 9.40628C19.4188 10.2061 19.5881 11.0635 19.586 11.929C19.582 15.568 16.625 18.521 12.994 18.521ZM16.609 13.587C16.412 13.488 15.439 13.009 15.256 12.941C15.074 12.876 14.941 12.842 14.811 13.04C14.678 13.237 14.298 13.686 14.184 13.815C14.07 13.948 13.952 13.963 13.754 13.865C13.557 13.765 12.918 13.557 12.162 12.88C11.572 12.3551 11.177 11.705 11.059 11.508C10.945 11.31 11.048 11.204 11.147 11.105C11.234 11.017 11.344 10.873 11.443 10.759C11.543 10.645 11.576 10.561 11.641 10.429C11.706 10.295 11.675 10.181 11.626 10.082C11.576 9.98305 11.181 9.00605 11.014 8.61205C10.854 8.22305 10.691 8.27705 10.569 8.27205C10.455 8.26505 10.322 8.26505 10.189 8.26505C10.0886 8.26755 9.98973 8.29078 9.89869 8.33328C9.80766 8.37579 9.7264 8.43665 9.66 8.51205C9.478 8.71005 8.969 9.18905 8.969 10.166C8.969 11.143 9.679 12.082 9.779 12.215C9.877 12.3481 11.173 14.347 13.162 15.207C13.632 15.412 14.002 15.533 14.291 15.625C14.766 15.7771 15.195 15.754 15.537 15.705C15.917 15.647 16.708 15.225 16.875 14.762C17.039 14.298 17.039 13.9021 16.989 13.819C16.94 13.7351 16.807 13.686 16.609 13.587Z"
                                fill="#1E1714"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                      <div class="footer__copy">
                        © 2012—{{ currentYear }}
                        Клиника "К+31"
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container site-container">
        <div class="row">
          <div class="col-md-2 col-sm-12">
            <div class="footer__bottom-title">Дополнительная информация</div>
          </div>
          <div class="col-sm-6 col-md-5">
            <div class="footer__bottom-text">
              <p>
                Травмпункт работает в круглосуточном режиме только для взрослых.
                Для детей ведётся приём детского травматолога в соответствие с
                расписанием.
              </p>
              <p>
                Обращаем ваше внимание, что вся информация, включая цены,
                предоставлена для ознакомления и не является публичной офертой
                (ст. 435 ГК РФ, cт. 437 ГК РФ). Для получения более детальных
                консультаций по услугам и их стоимости обращайтесь в колл-центр
                по телефонам, указанным выше.
              </p>
              <p>ЭКО проводится по ОМС и за наличный расчёт.</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-5">
            <div class="footer__bottom-text">
              <p>
                Цены действительны для потребителей — физических лиц. Оплата
                возможна за наличный расчёт, дебетовыми и кредитными картами, и
                банковскими переводами. Внести аванс онлайн.
              </p>
              <p>
                Материалы, размещённые на данной странице, носят информационный
                характер. АО «К+31» не несёт ответственности за возможные
                негативные последствия, возникшие в результате использования
                информации, размещённой на сайте k31.ru. У медицинских услуг
                имеются противопоказания, необходима консультация специалиста.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "TheFooter",
  setup() {
    const menuIsOpen = ref(false);
    const showMenu = () => {
      menuIsOpen.value = true;
    };
    const currentYear = computed(() => {
      return new Date().getFullYear();
    });
    return {
      showMenu,
      menuIsOpen,
      currentYear,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer__copy {
  font-weight: 400;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 35px;
}

.footer__text {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  max-width: 700px;
  margin-bottom: 32px;

  & a {
    text-decoration: underline;
  }
}

.footer {
  padding-top: 32px;
  padding-bottom: 77px;
  @media only screen and (max-width: 1023px) {
    padding-top: 32px;
    padding-bottom: 50px;
  }

  @include phone {
    padding-bottom: 0;
  }

  .row {
    row-gap: 10px;
  }

  .socials__link {
    display: flex;
    width: 25px;
    height: 25px;
    @media only screen and (max-width: 559px) {
      width: 35px;
      height: 35px;
    }
  }
}

.socials {
  display: flex;
  align-items: center;
  gap: 30px;
  @media only screen and (max-width: 559px) {
    gap: 20px;
  }
}

.footer__bottom-title {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.footer__bottom {
  padding: 40px 0 80px;
}

.footer__socials {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__lang {
  display: flex;
  align-items: center;
}

.contacts-call {
  display: flex;
  align-items: flex-end;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  border-radius: 100px;
  font-size: 12px;
  width: 225px;
  padding: 16px 10px;
  justify-content: flex-start;
  text-align: center;
  text-transform: uppercase;
  transition: 0.2s;
  margin-bottom: 1em;
  border: 1px solid #fff;
  margin-top: 20px;

  svg {
    margin-right: 5px;
  }
}

.footer__lang-item {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  width: 48px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.footer__bottom-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.54;
  color: rgba(255, 255, 255, 0.5);

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.contacts-parking {
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 11px 19px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    border: none;
    border-radius: 0;
    padding: 0;
    width: 100%;
    margin-top: 32px;
  }

  &.active {
    .contacts-parking__links {
      display: block;
    }
  }
}

.contacts-parking__links {
  display: none;
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #fff;
  padding: 20px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  text-align: left;

  @media only screen and (max-width: 767px) {
    position: relative;
    display: none;
    bottom: auto;
    left: auto;
    padding: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  a {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.6px;
    line-height: 1;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 767px) {
      border: 1px solid #fff;
      border-radius: 30px;
      padding: 14px 10px;
      font-size: 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        margin-top: 6px;
      }
    }

    svg {
      margin-right: 5px;
      height: 16px;
      width: 16px;
      flex-shrink: 0;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
  }
}

.contacts-parking__links.contacts-parking__links--mobile {
  display: none;
  width: 270px;
  margin-top: 24px;
  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.contacts-parking__link--1 {
  color: #fff;
}

.contacts-parking__link--3 {
  color: #fff;
}

.contacts-parking__btn {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.contacts-parking__icon {
  margin-right: 7px;
}

.footer__address-text {
  font-weight: 400;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.49);
  @media only screen and (max-width: 559px) {
    font-size: 16px;
  }

  a {
    color: rgba(255, 255, 255, 0.49);
    transition: 0.2s;

    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}

.footer__socials-left {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .footer__address {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
  }
  .footer__address-item {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 65px;
    }
  }
}

@media only screen and (max-width: 559px) {
  .footer {
    .col-xs-6 {
      width: 100%;
    }
  }
  .footer__address {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .footer__address-item {
    margin-bottom: 30px;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .footer__socials {
    flex-direction: column;
    align-items: flex-start;
  }
  .socials {
    margin-bottom: 55px;

    @include phone {
      margin-bottom: 10px;
    }
  }
  .footer__socials-left {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
  }
  .footer__copy {
    margin-left: 0;
  }
  .footer__socials-left {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 991px) {
  .footer__bottom-title {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .footer__logo {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .col-md-4 {
    width: 33.33333333%;
  }
  .footer__nav {
    margin-bottom: 20px;
  }
  .contacts-parking {
    margin-right: 0;
    margin-left: 0;
  }
  .footer__logo {
    margin-bottom: 9px;
  }
  .footer__bottom {
    padding-top: 0;
  }
}

.footer__address-name {
  font-weight: 400;
  font-size: 13px;
  color: #fff;
  margin-bottom: 10px;
  @media only screen and (max-width: 559px) {
    font-size: 16px;
  }
}

.footer__address-item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.contacts-parking__text {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
}

.footer__development {
  font-weight: 400;
  font-size: 13px;
  color: #fff;
  text-align: right;

  &:hover {
    text-decoration: underline;
  }
}

.footer__nav {
  nav li {
    line-height: 1;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  nav a {
    font-weight: 400;
    font-size: 14px;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer__wrapper {
  background: #1e1714;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .col-xs-6 {
    width: 50% !important;
  }
}
</style>
