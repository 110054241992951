<script>
import { reactive, ref, toRefs, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";
import FormInput from "@/components/form/FormInput.vue";
import FormInputCheckbox from "@/components/form/FormInputCheckbox.vue";
import BtnLink from "@/components/ui/BtnLink.vue";
import axios from "axios";
import TheLoader from "@/components/ui/TheLoader.vue";

export default {
  name: "FormTakeYourself",
  emits: ["closeForm"],
  components: { TheLoader, BtnLink, FormInputCheckbox, FormInput },
  props: {
    modalIsOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    userId: {
      type: [Number, null],
      required: true,
      default: null,
    },
    promoInfo: {
      type: [Object, null],
      required: true,
      default: null,
    },
    message: {
      type: [String, null],
      required: true,
      default: null,
    },
  },
  setup(props) {
    const { modalIsOpen, userId, promoInfo, message } = toRefs(props);
    const formIsSubmit = ref(false);
    const formIsSubmitMessage = ref(
      "Форма успешно отправлена, с вам скоро свяжутся!"
    );
    const error = ref(false);
    const loading = ref(false);
    const errorMsg = ref("Произошла ошибка, попробуйте позже");
    const userInfo = reactive({
      name: null,
      phone: null,
      isTerms: false,
      rules: false,
    });

    const userRules = {
      name: { required },
      phone: { required },
      isTerms: { sameAs: sameAs(true) },
      rules: { sameAs: sameAs(true) },
    };

    const v$ = useVuelidate(userRules, userInfo);

    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) {
        return;
      }

      const params = new URLSearchParams({
        uid: userId.value,
      }).toString();

      const data = {
        promo_id: promoInfo.value.promo_id,
        name: userInfo.name,
        phone: userInfo.phone,
        is_terms: 1,
        is_rules: 1,
      };

      try {
        const data = {
          name: userInfo.name,
          phone: userInfo.phone,
          message: message.value.replace(/(<([^>]+)>)/gi, ""),
        };

        window.Comagic.addOfflineRequest(data);
      } catch (e) {
        console.log("e magic", e);
      }

      try {
        loading.value = true;
        const response = await axios.post(`/v1/ny/order/?${params}`, data);

        if (response.data.status !== "ok") {
          error.value = true;
          errorMsg.value = response.data.message;
          return;
        }

        if (response.data.status === "ok") {
          formIsSubmit.value = true;
          formIsSubmitMessage.value =
            response.data.message ||
            "Форма успешно отправлена, с вам скоро свяжутся!";
        }
      } catch (err) {
        error.value = true;
        errorMsg.value = err.message || "Произошла ошибка, попробуйте позже";
      } finally {
        loading.value = false;
      }
    };

    const resetForm = () => {
      v$.value.$reset();
      error.value = false;
      errorMsg.value = "Произошла ошибка, попробуйте позже";
      formIsSubmit.value = false;
      userInfo.name = null;
      userInfo.phone = null;
      userInfo.isTerms = false;
      userInfo.rules = false;
    };

    watch(modalIsOpen, (isShow) => {
      if (!isShow) {
        resetForm();
      }
    });

    return {
      userInfo,
      v$,
      submit,
      error,
      errorMsg,
      formIsSubmit,
      loading,
      formIsSubmitMessage,
    };
  },
};
</script>

<template>
  <form
    action=""
    class="yourself"
    @submit.prevent="submit"
    @keyup.enter.prevent="submit"
  >
    <div class="yourself__wrapper">
      <TheLoader v-show="loading" />
      <button @click="$emit('closeForm')" type="button" class="yourself__close">
        <img src="@/assets/uploads/icon/close.svg" alt="крестик закрытия" />
      </button>
      <div v-if="!formIsSubmit && !loading" class="yourself__form-wrapper">
        <h4 class="yourself__title">
          Оставьте, пожалуйста, Ваши контактные данные!
        </h4>
        <p class="yourself__text">
          Наши чудесные менеджеры свяжутся с Вами и подберут удобный день и
          время для того, чтобы вы воспользовались <span>подарком</span>!
        </p>
        <div v-if="error" class="error">
          {{ errorMsg }}
        </div>
        <div class="yourself__group">
          <FormInput
            :validError="v$.name.$error"
            errorMsg="Поле не заполнено"
            v-model="userInfo.name"
            placeholder="Имя и Фамилия"
            name-id="agent_first_name"
            required
          />
          <FormInput
            v-model="userInfo.phone"
            name-id="agent_phone"
            :validError="v$.phone.$error"
            placeholder="Номер телефона"
            errorMsg="Поле не заполнено"
            type="tel"
          />
        </div>
        <div class="yourself__group">
          <FormInputCheckbox
            id="register-checkbox"
            v-model="userInfo.isTerms"
            label="Я согласен на"
            linkLabel="обработку моих персональных данных"
            href="https://www.k31.ru/info/soglasie/"
            :validError="v$.isTerms.$error"
          />
          <FormInputCheckbox
            id="profile-rules"
            v-model="userInfo.rules"
            label="Я ознакомлен с"
            linkLabel="правилами внутреннего распорядка"
            href="https://www.k31.ru/upload/doc/useterms.pdf"
            :validError="v$.rules.$error"
          />
        </div>
        <BtnLink @click.prevent="submit" white class="yourself__submit">
          <span>Отправить</span>
        </BtnLink>
      </div>
      <div v-if="formIsSubmit && !loading" class="yourself__form-wrapper">
        <h4 class="yourself__title">
          {{ formIsSubmitMessage }}
        </h4>
      </div>
    </div>
  </form>
</template>

<style scoped lang="scss">
.yourself {
  display: flex;
  justify-content: center;
}

.yourself__wrapper {
  position: relative;
  width: 100%;
  max-width: 620px;
  padding: 50px 70px;
  background: linear-gradient(139deg, #f6bc65 42.9%, #b7853b 76%);
  border-radius: 24px;
  border: 3px solid #58311a;
  stroke-width: 4px;
  stroke: #58311a;

  @include phone {
    padding: 40px 10px;
  }
}

.yourself__form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.yourself__close {
  position: absolute;
  right: 0;
  top: -35px;
  border: unset;
  padding: 0;
  transition: opacity 0.3s ease-in-out;

  & img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 0.7;
  }
}

.yourself__title {
  color: #150a08;
  text-align: center;
  font-family: Playfair Display, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.28px;

  @include phone {
    font-size: 20px;
    line-height: 24px;
  }
}

.yourself__text {
  color: #150a08;
  text-align: center;
  font-size: 18px;
  line-height: 22px;

  & span {
    text-transform: uppercase;
  }

  @include phone {
    font-size: 14px;
    line-height: 18px;
  }
}
.yourself__group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.yourself__checkboxes {
}

.yourself__submit {
}
</style>
